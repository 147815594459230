import { mdiMapMarkerOff } from "@mdi/js";
import { Box, Stack } from "@mui/material";
import { forwardRef } from "react";
import { Icon } from "@mdi/react"

const GMapsBaseBox = forwardRef(({
  defaultMessage = "Mapa no disponible",
  defaultIconPath = mdiMapMarkerOff,
  ...props
}, ref)=>{
  return (
    <Box {...props} ref={ref} sx={{ width:"100%", height:500, backgroundColor:theme=>theme.palette.grey[200],display:"flex", justifyContent:"center", ...props.sx}}>
      <Stack spacing={2} sx={{alignSelf:"center", justifySelf:"center"}}>
        <Box sx={{alignSelf:"center",display:"inline-flex"}}>        
          <Icon path={defaultIconPath} size={2}/>
        </Box>
        <div>{defaultMessage}</div>
      </Stack>
    </Box>
  )
})

export default GMapsBaseBox;