import { createContext, useContext, useEffect, useState } from "react"
import axios from "../axios"

export const GoogleMapsProvider = ({children}) => {
  const [ maps, setMaps ] = useState(null)

  useEffect(()=>{
    let id = "gmaps-loader"
    let script = document.getElementById(id)
    if(!script){
      script = document.createElement("script")
      script.id = id
      document.body.appendChild(script);
    }
    axios.get('gmaps')
    .then((response)=>{
      script.text = response.data
      setMaps(window.google.maps)
    }).catch(e=>{
      script.text= e.response.data
    })
  },[])

  useEffect(()=>{
    if(!maps) return
    setMaps(window.google.maps)
  },[window?.google?.maps])

  return children;
}

export const GoogleMapWrapper = ({children})=>{
  return window?.google?.maps && children
}