import { TextField } from "@mui/material"

/** @type { import('@mui/material').TextField } */
const TrimmedTextField = ({onChange, value, ...props}) =>{
  const handleChange = e => {
    let v = e.target.value
      .replace(/^\s*/g, '')
      .replace(/([^\s]) +([^\s])/g, "$1 $2")
      .replace(/\s+$/, " ");
    e.target.value = v;
    onChange && onChange(e)
  }

  const handleBlur = e => {
    const v = e.target.value
    const t = v.trim()
    if (v !== t){
      e.target.value = t
      onChange && onChange(e)
    }
  }

  return (
    <TextField 
      {...props}
      value={value} 
      onChange={handleChange}
      onBlur={handleBlur} 
    />
  )
}

export default TrimmedTextField;