export const PipelineProvider = ({ providers = [], children }) => {
  let providerChain = children;

  // Iterate through providers in reverse order to avoid unnecessary nesting
  for (let i = providers.length - 1; i >= 0; i--) {
    const [Provider, options] = providers[i];
    providerChain = <Provider {...options}>{providerChain}</Provider>;
  }

  return providerChain;
};