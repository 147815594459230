import _axios from 'axios';

const axios = _axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

// Interceptor to set Authorization header
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

export const axiosPostFactory = (route) => async (data) => {
  return new Promise((resolve,reject)=>{
    axios
      .post(route,data)
      .then(({data})=>{
        resolve(data)
      })
      .catch(reject)
  })
}

export const axiosGetFactory = (route) => async (query) => {
  return new Promise((resolve,reject)=>{
    const params = new URLSearchParams(query).toString()

    axios
      .get(`${route}?${params}`)
      .then(({data})=>{
        resolve(data)
      })
      .catch(reject)
  })
}

export const axiosGetByIdFactory = (route) => async (id) => {
  return new Promise((resolve,reject)=>{
    axios
      .get(`${route}/${id}`)
      .then(({data})=>{
        resolve(data)
      })
      .catch(reject)
  })
}

export const axiosGetByFriendlyURLFactory = (route) => {
  const constructURL = (route, params) => {
    let constructedURL = route;
  
    for (const [key, value] of Object.entries(params)) {
      constructedURL = constructedURL.replace(`:${key}`, encodeURIComponent(value));
    }
  
    return constructedURL;
  };

  return (params) => {
    return new Promise((resolve, reject) => {
      const constructedURL = constructURL(route, params);

      axios.get(constructedURL)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  };
};


export const axiosPutByIdFactory = (route) => async (id,data) => {
  return new Promise((resolve,reject)=>{
    axios
      .put(`${route}/${id}`,data)
      .then(({data})=>{
        resolve(data)
      })
      .catch(reject)
  })
}

export const axiosDeleteByIdFactory = (route) => async (id,data) => {
  return new Promise((resolve,reject)=>{
    axios
      .delete(`${route}/${id}`)
      .then(({data})=>{
        resolve(data)
      })
      .catch(reject)
  })
}

export const axiosDownloadFileFactory = (route) => async (query) => {
  return new Promise((resolve,reject)=>{
    const params = new URLSearchParams(query).toString()

    axios
      .get(`${route}?${params}`,{
        responseType: 'blob'
      })
      .then((response)=>{
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['content-disposition']?.split('filename=')[1]);
        document.body.appendChild(link);
        link.click();
        resolve()
      })
      .catch(reject)
  })
}

export default axios;