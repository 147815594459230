import { TextField } from "@mui/material"
import Select from "../../components/Select";

const PLANILLA_FRECUENCIA_OPTIONS = [
  {value:"12H", label: "Dos veces al día"},
  {value:"1D", label: "Diario"},
  {value:"1W", label: "Semanal"},
  {value:"1M", label: "Mensual"},
  {value:"6M", label: "Semestral"},
  {value:"1Y", label: "Anual"}
]

const PLANILLA_FRECUENCIA_REGEX = /(?<frequency>\d+) ?(?<calendarCode>[iHDWMY])/

const FrecuenciaSelector = ({ value, onChange, disabled}) =>{
  
  const isFrequencyError = (x => x && !PLANILLA_FRECUENCIA_REGEX.exec(x))(value)

  return <>
    <TextField
      size="small"
      value={value ?? ""}
      onChange={e => onChange(e.target.value??"")}
      error={isFrequencyError}
      sx={{width: 96}}
      disabled={disabled}
    />

    <Select
      options={[
        ...PLANILLA_FRECUENCIA_OPTIONS,
        ...(()=>{
          const valueIsOption = PLANILLA_FRECUENCIA_OPTIONS.find(x => x.value === value)?.value
          if(valueIsOption) return []
                    
          if(!value) return []
          
          const match = PLANILLA_FRECUENCIA_REGEX.exec(value)

          if(!match) return []
          
          const { frequency, calendarCode} = match.groups

          const calendarCodeText = {
            i: "minutos",
            H: "horas",
            D: "días",
            W: "semanas",
            M: "meses",
            Y: "años",
          }[calendarCode]

          return [ { label: `Cada ${frequency} ${calendarCodeText}`, value} ]
        })()
      ]}
      label="Frecuencia"
      value={value ?? ""}
      onChange={v=>{onChange(v)}}
      size="small"
      error={isFrequencyError}
      disabled={disabled}
    />
  </>
}

export default FrecuenciaSelector;