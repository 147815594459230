import { useReducer } from "react"

const usuarioCamposReducer = (state,action) => {
  const actions = {
    SEED(_,{usuarios,campos}){
      const newState = {}
      usuarios.forEach(usuario=>{
        newState[usuario.usuario_id] = campos.filter(c=>usuario.campo_ids.some(ci=>ci === c.campo_id))
      })
      return newState
    },
    UPDATE(state,{usuarioId,campos}){
      const newState = {...state}
      newState[usuarioId] = campos;
      return newState
    }
  }

  return actions[action.type]?.(state,action)
}

const useUsuarioCampos = () => {
  const [ value, dispatch ] = useReducer(usuarioCamposReducer,{})

  return {
    /** @type {Record<number,Array<number>>} */
    value,
    seed: ({usuarios, campos}) => dispatch({type: "SEED", usuarios, campos }),
    update: ({usuarioId, campos}) => dispatch({type: "UPDATE", usuarioId, campos})
  }
}

export default useUsuarioCampos;