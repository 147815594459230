import { 
  axiosGetByIdFactory, 
  axiosGetFactory, 
  axiosPostFactory, 
  axiosPutByIdFactory,
  axiosDeleteByIdFactory,
} from "../axios";

export const getSectores = axiosGetFactory('/sector')
export const getSectorById = axiosGetByIdFactory('/sector')
export const postSector = axiosPostFactory('/sector')
export const putSector = axiosPutByIdFactory('/sector')
export const deleteSectorById = axiosDeleteByIdFactory('/sector')