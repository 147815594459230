import BaseBox from "../../components/BaseBox";
import NavDrawer from "../../components/Drawer/NavDrawer";
import { PlanillasProvider, usePlanillas } from "./PlanillasContext";
import PlanillasSelectPanel from "./PlanillaSelectPanel";
import PlanillasDetail from "./PlanillaDetail";
import { SupervisoresProvider } from "../../contexts/SupervisoresProvider";

const Content = ()=>{
  const {  empresaSelected } = usePlanillas()
  return (
    <SupervisoresProvider empresaId={empresaSelected?.empresa_id}>
      <NavDrawer/>
      <PlanillasSelectPanel />
      <PlanillasDetail/>
    </SupervisoresProvider>
  )
}

const Planillas = () => {
  return (
    <BaseBox>
      <PlanillasProvider>
        <Content/>
      </PlanillasProvider>
    </BaseBox>
  );
}

export default Planillas;