import React, { createContext, useContext, useState, useEffect } from 'react';

const GPSContext = createContext();

export const useGPS = () => {
  return useContext(GPSContext);
};

export const GPSProvider = ({ children }) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Function to get GPS data
    const getGPSData = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            setError(error.message);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    };

    getGPSData();

    // Cleanup function
    return () => {
      // Cleanup if necessary
    };
  }, []); // Empty dependency array ensures useEffect runs only once after initial render

  return (
    <GPSContext.Provider value={{ position: [longitude,latitude], lngLatPosition: {lng:longitude,lat:latitude}, error }}>
      {children}
    </GPSContext.Provider>
  );
};
