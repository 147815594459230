import { createContext, useContext, useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage"
import { useTheme, useMediaQuery } from "@mui/material";

const DrawerContext = createContext(null);

export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider = ({ children }) => {
  const {breakpoints} = useTheme()
  const lg = useMediaQuery(breakpoints.up("lg"))

  const CLOSED = "0"
  const OPEN = "1"

  const [openDesktop, setOpenDesktop] = useLocalStorage("drawer-desktop", OPEN);
  const [openMobile, setOpenMobile] = useLocalStorage("drawer-mobile", CLOSED);

  const setOpen = (value) => {
    if(lg){
      setOpenDesktop(value)
    }else{
      setOpenMobile(value)
    }
  }

  const toggleDrawer = ()=>{
    const toggler = p => p === OPEN ? CLOSED : OPEN;
    if(lg){
      setOpenDesktop(toggler(openDesktop))
    }else{
      setOpenMobile(toggler(openMobile))
    }
  }

  useEffect(()=>{
    if(lg){
      setOpenMobile(CLOSED)
    }
  },[lg])

  const open = (lg ? openDesktop : openMobile) === OPEN;

  return (
    <DrawerContext.Provider value={{ open, lg, setOpen, toggleDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};
