export const formatDate = (dateStr)=>{
  const date = new Date(dateStr);
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const ONE_DAY = 24*60*60;

  if(date.getDay() === now.getDay() && Math.abs(diff) < ONE_DAY)
    return "HOY"
  
  if(diff > ONE_DAY && diff < 2 * ONE_DAY)
    return "MAÑANA"

  return dateStr?.split('T')?.[0]
}

