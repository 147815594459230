import React, { useState, useEffect } from "react";
import { Card, CardContent, List, ListItem, ListItemText } from "@mui/material";
import AddItemBox from "../../components/AddItemBox";
import { getCategorias, postCategoria } from "../../client/Categoria";
import EmpresaSearchPanel from "../../components/EmpresaSearchPanel";
import MainContainer from "../../components/MainContainer";
import BaseBox from "../../components/BaseBox";
import NavDrawer from "../../components/Drawer/NavDrawer";
import { usePopup } from "../../contexts/PopupProvider";

const Categorias = () => {
  const [empresaId, setEmpresaId] = useState(null)
  const [categorias, setCategorias] = useState([]);
  const popup = usePopup()

  const fetchCategorias = (empresa_id) => {
    getCategorias({empresa_id})
      .then((categoriasData) => {
        setCategorias(categoriasData);
      })
      .catch((error) => {
        console.error("Error fetching categorias:", error);
      });
  };

  const handleSelectEmpresa = (empresaId)=>{
    setEmpresaId(empresaId)
    fetchCategorias(empresaId)
  }

  const handleSubmit = (nombre) => {
    postCategoria({nombre, empresa_id: empresaId})
      .then((newCategoria) => {
        setCategorias([...categorias, newCategoria]);
      })
      .catch((error) => {
        popup.error("No se pudo agregar la categoría:")
        console.error("Error adding categoria:", error);
      });
  };

  return (
    <BaseBox>
      <NavDrawer/>
      <MainContainer>
        <EmpresaSearchPanel onSelect={handleSelectEmpresa}/>

        <Card>
          <List>
            {categorias.map((categoria, index) => (
              <ListItem key={index}>
                <ListItemText primary={categoria.nombre} />
              </ListItem>
            ))}
            <AddItemBox onSubmit={handleSubmit} label="Nueva Categoría" />
          </List>
        </Card>
      </MainContainer>
    </BaseBox>
  );
};

export default Categorias;
