import { useReducer } from "react"

export const crudReducer = (idKey) => (state, action) => {
  const upsertItem = (items, newItem, strict = false) => {
    const index = items.findIndex(item => item[idKey] === newItem[idKey]);
    if (index === -1) {
      return [...items, newItem];
    } else if(strict){
      const newState = [...items];
      newState[index] = newItem
      return newState;
    } else {
      const newState = [...items];
      newState[index] = {
        ...newState[index],
        ...newItem
      }
      return newState;
    }
  };

  switch (action.type) {
    case "SET":
      return action.payload;
    case "UPSERT":
      return upsertItem(state, action.payload);
    case "UPSERT_STRICT":
      return upsertItem(state, action.payload, true);
    case "UPSERT_MANY":
      let newState = [...state];
      action.payload.forEach(newItem => {
        newState = upsertItem(newState, newItem);
      });
      return newState;
    case "DROP":
      return state.filter(item => item[idKey] !== action.payload[idKey]);
    default:
      return state;
  }
};

export const useCrudReducer = (idKey) => {
  const [ state, dispatch ] =  useReducer(crudReducer(idKey),[]);

  return {
    state,
    set: (payload) => dispatch({ type: "SET", payload }),
    upsert: (payload) => dispatch({ type: "UPSERT", payload }),
    upsertStrict: (payload) => dispatch({ type: "UPSERT_STRICT", payload }),
    drop: (payload) => dispatch({ type: "DROP", payload }),
    upsertMany: (payload) => dispatch({ type: "UPSERT_MANY", payload }),
  }
}