import { useState, useEffect } from "react"
import { postCultivo, deleteCultivoById } from "../../client/Cultivo"
import { Box, Card, List, ListItem, ListItemButton, ListItemText, Typography, IconButton } from "@mui/material";
import { Icon }  from "@mdi/react";
import { mdiDelete } from "@mdi/js";
import AddItemBox from "../../components/AddItemBox";
import { useCultivosVariedades } from "./CultivosVariedadesContext";
import { usePopup } from "../../contexts/PopupProvider";
import DeleteDialogButton from "../../components/DeleteDialogButton";

const CultivosPanel = ({ sectorId, value, onChange })=>{
  const { cultivos, variedades } = useCultivosVariedades();
  const [ nombre, setNombre ] = useState("")
  const popup = usePopup()

  const [ isDeleteDialogOpen, setIsDeleteDialogOpen ] = useState(false)

  useEffect(()=>{
    onChange(null)
    setNombre("")
  },[sectorId])

  const handleSubmit = (nombre)=>{
    postCultivo({ nombre, sector_id: sectorId})
      .then((cultivo)=>{
        onChange(cultivo.cultivo_id)
        setNombre("")
        cultivos.upsert(cultivo)
      })
      .catch(()=>{
        popup.error("No se pudo registrar el cultivo")
      })
  }

  const [ cultivoToDelete, setCultivoToDelete ] = useState(null)

  const handleClickDelete = (cultivo) => (e) => {
    e.stopPropagation();
    setIsDeleteDialogOpen(true)
    setCultivoToDelete(cultivo)
  }

  const handleDelete = ()=>{
    if(!cultivoToDelete) return;

    deleteCultivoById(cultivoToDelete.cultivo_id)
      .then(()=>{
        cultivos.drop(cultivoToDelete)
        setIsDeleteDialogOpen(false)
        popup.success("Cultivo eliminado")

        if(cultivoToDelete.cultivo_id === value){
          onChange(null)
        }
      })
      .catch(()=>{
        popup.error("No se pudo eliminar el cultivo")
      })

  }

  return <>
    <Typography variant="h5">
      Cultivos
    </Typography>
    <Card>
      <List>
        {
          cultivos.state?.length > 0 
          ?
          cultivos.state.map((cultivo)=>(
            <ListItem
              disablePadding
              key={cultivo.cultivo_id}
              sx={{
                "&:not(:hover) .optionButton":{
                  display: "none"
                },
                "& .optionButton":{
                  marginTop: -1,
                  marginBottom: -1
                }
              }}
            >
              <ListItemButton selected={cultivo.cultivo_id === value} onClick={()=>onChange(cultivo.cultivo_id)}>
                <ListItemText>
                  {cultivo.nombre}
                </ListItemText>
                <Box className="optionButton">
                  <IconButton onClick={ handleClickDelete(cultivo) }>
                    <Icon path={mdiDelete} size={1}/>
                  </IconButton>
                </Box>
              </ListItemButton>
            </ListItem>
          ))
          :
          null
        }
        <AddItemBox 
          value={nombre}
          onSubmit={handleSubmit}
          disabled={!sectorId}
          label = {
            sectorId
            ? "Nuevo cultivo"
            : "Seleccione un sector"
          }  
        />
      </List>
    </Card>
    
    <DeleteDialogButton
      open={isDeleteDialogOpen}
      onOpenChange={setIsDeleteDialogOpen}
      onConfirm={handleDelete}
      title="Eliminar Cultivo"
      question={
        cultivoToDelete ?
        `¿Está seguro que desea eliminar el cultivo ${cultivoToDelete.nombre}?`
        : ''
      }
    />
  </>
}

export default CultivosPanel;