import { createContext, useContext, useState, useEffect } from "react";
import { getPlanillas } from "../../client/Planilla"
import { useCrudReducer } from "../../hooks/useCrudReducer";

const PlanillasContext = createContext({
  planillas: null,
  planillaIdSelected: null,
  setPlanillaIdSelected: () => {},
  empresaSelected: null,
  setEmpresaSelected: () => {},
})

export const usePlanillas = () => useContext(PlanillasContext)

export const PlanillasProvider = ({ children }) => {
  const planillas = useCrudReducer('planilla_id');
  const [ planillaIdSelected, setPlanillaIdSelected ] = useState(null);
  const [ empresaSelected, setEmpresaSelected ] = useState(null);

  useEffect(() => {
    getPlanillas()
      .then((data) => {
        planillas.set(data)
        if(data.length == 1){
          setPlanillaIdSelected(data[0].planilla_id)
        }
      })
      .catch(()=>{});
  }, []);
  
  return (
    <PlanillasContext.Provider value={{ planillas, planillaIdSelected, setPlanillaIdSelected, empresaSelected, setEmpresaSelected }}>
      { children }
    </PlanillasContext.Provider>
  )
}
