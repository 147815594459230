import { renderToString } from "react-dom/server"
const createMdiGlyph = ({path})=>{
  const parser = new DOMParser();
  const pinSvgString = renderToString(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill="currentColor" d={path}/>
    </svg>
  );

  const pinSvgElement = parser.parseFromString(
    pinSvgString,
    "image/svg+xml"
  ).documentElement;

  return pinSvgElement;
}

export default createMdiGlyph;