import { Autocomplete, ListItemButton, TextField } from "@mui/material"
import { useEffect, useState } from "react"

const SupervisorAutocomplete = ({ supervisores, value = [], onChange, disabled })=>{
  
  const innerOptions = (supervisores??[]).map(s=>({
    ...s,
    na: s.nombres + " " + s.apellidos
  }))

  const getInnerValue = () => innerOptions.filter(o => (value ?? []).includes(o.usuario_id));

  const [ innerValue, setInnerValue ] = useState(getInnerValue())

  useEffect(()=>{
    setInnerValue(getInnerValue())
  },[value])
  
  return (
    <Autocomplete
      multiple
      size="small"
      options={innerOptions}
      getOptionKey={option => option.usuario_id}
      getOptionLabel={option => option.na}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Supervisor(es)"
        />
      )}
      sx={{ flex: 1 }}
      value={innerValue??null}
      onChange={(_, v) => onChange && onChange(v.map(x=>x.usuario_id))}
      isOptionEqualToValue={(o,v)=> o.usuario_id === v?.usuario_id}
      disabled={disabled}
    />
  );
}

export default SupervisorAutocomplete;