import { Grid } from "@mui/material";
import SimpleDataRow from "./SimpleDataRow";
import LocalidadCard from "./LocalidadCard";

const DataEmpresa = ({data})=>{
  return (
    <LocalidadCard node={{ ...data, type: "empresa" }}>
      <Grid container spacing={2}>
        <SimpleDataRow label="Nombre" value={data.nombre}/>
        <SimpleDataRow label="Rut" value={data.rut}/>
        <SimpleDataRow label="Direccion" value={data.direccion}/>
        <SimpleDataRow label="Comuna" value={data.comuna_id}/>
        <SimpleDataRow label="Representante Legal" value={data.representante_legal}/>
      </Grid>
    </LocalidadCard>
  )
  
}

export default DataEmpresa;