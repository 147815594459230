import { Box, Stack, TextField } from "@mui/material"
import { Icon } from "@mdi/react"
import { mdiAlphaXBox, mdiNumeric, mdiText } from "@mdi/js"
import LimitButton, { LIMIT_DISABLED, LIMIT_EQUALS, LIMIT_STRICT } from "./LimitButton"
import { useState, useEffect } from "react"

const DataTypeRange = ({ value, onChange, integer, disabled }) => {
  const [ min, setMin ] = useState(value.gt ?? value.gte ?? 0)
  const [ max, setMax ] = useState(value.lt ?? value.lte ?? 0)

  const decode = (x) => ( 
    value[x + 't'] !== undefined ? LIMIT_STRICT :
    value[x + 'te'] !== undefined ? LIMIT_EQUALS :
    LIMIT_EQUALS
  )
  const encode = (limit)=>(
    limit === LIMIT_STRICT ? 't' :
    limit === LIMIT_EQUALS ? 'te' :
    null
  )

  const [ minLimit, setMinLimit] = useState(decode('g'))
  const [ maxLimit, setMaxLimit] = useState(decode('t'))
  
  useEffect(()=>{
    const options = {}
    
    const minEncoded = encode(minLimit)
    if(minEncoded) { options['g'+minEncoded] = integer ? Math.floor(min) : min }
    
    const maxEncoded = encode(maxLimit)
    if(maxEncoded) { options['l'+maxEncoded] = integer ? Math.floor(max) : max }
    
    if(onChange) { onChange(options) }

  },[min,max,minLimit,maxLimit])

  const isError = minLimit !== LIMIT_DISABLED && maxLimit !== LIMIT_DISABLED
    && !isNaN(min) && !isNaN(max) && min > max || (min === "" || max === "")

  return (
    <Stack direction="row" alignItems="center">
      <TextField
        size="small"
        type="number"
        label={minLimit === LIMIT_DISABLED ? "Sin Mínimo (-♾️)": "Mínimo" }
        value={ minLimit ? min : ""}
        onChange={e=>setMin(Number(e.target.value))}
        disabled={ disabled || minLimit === LIMIT_DISABLED }
        error={ isError }
      />

      <LimitButton
        value={ minLimit }
        onChange={setMinLimit}
        disabled={disabled}
      />

      <Box sx={{opacity: disabled ? 0.5 : 1, display: "inline-flex"}}>
        <Icon path={mdiAlphaXBox} size={1} />
      </Box>

      <LimitButton
        value={maxLimit}
        onChange={setMaxLimit}
        disabled={disabled}
      />

      <TextField
        size="small"
        type="number"
        label={ maxLimit === LIMIT_DISABLED ? "Sin máximo (+♾️)" : "Máximo"}
        value={ maxLimit ? max : ""}
        onChange={ e=>setMax(Number(e.target.value))}
        disabled={ disabled || maxLimit === LIMIT_DISABLED }
        error={ isError }
      />
    </Stack>
  )
}

export default DataTypeRange