import { Box, Button, TextField } from "@mui/material";
import { Icon } from "@mdi/react";
import { useEffect, useState } from "react";
import { mdiPlus } from "@mdi/js";

const AddItemBox= ({value,onSubmit,disabled,label,placeholder})=>{
  const [innerValue,setInnerValue]= useState(value)
  const [error,setError]= useState(false)
  const trimmed = String(innerValue ?? "").trim()
  
  const handleSubmit = ()=>{
    if(!trimmed) return setError(true)
    onSubmit(trimmed)
  }

  useEffect(()=>{
    if(!trimmed) return
    setError(false)
  },[trimmed])

  return (
    <Box sx={{display:"flex",px:1}}>
      <TextField
        fullWidth
        disabled={disabled}
        value={innerValue}
        onChange={(e)=>setInnerValue(e.target.value)}
        label={label}
        placeholder={placeholder}
        onKeyDownCapture={e=>{
          if(e.key === 'Enter')
            handleSubmit()
        }}
        error={error}
      />
      <Button variant="contained" sx={{ml:2}} disabled={disabled} onClick={handleSubmit}>
        <Icon path={mdiPlus} size={1}/>
      </Button>
    </Box>
  );
}

export default AddItemBox;