import MainContainer from "../../components/MainContainer"
import { usePlanillas } from "./PlanillasContext"
import { Stack, Typography, Button, Alert, Box, Card, CardContent } from "@mui/material"
import { List, ListItem, ListItemButton } from "@mui/material"
import { Icon } from "@mdi/react";
import { useCategorias } from "./CategoriasContext";
import { useSupervisores } from "../../contexts/SupervisoresProvider";
import { useEffect } from "react";
import { mdiContentSave, mdiTableLock } from "@mdi/js"

import PlanillaHeader from "./PlanillaHeader"
import PlanillaSchema from "./PlanillaSchema";
import { PlanillaEditorContext } from "./PlanillaEditorContext";
import { useCrudReducer } from "../../hooks/useCrudReducer";
import { useStorageReducer } from "../../hooks/useStorageReducer";
import { putPlanilla } from "../../client/Planilla";

import { usePopup } from "../../contexts/PopupProvider"

const PlanillaDetail = ()=>{
  const { categorias } = useCategorias();
  const { supervisores } = useSupervisores();
  const { planillaIdSelected, planillas } = usePlanillas();
  const popup = usePopup()

  const planilla = planillas.state.find(p => p.planilla_id === planillaIdSelected)

  const header = useStorageReducer({
    nombre: "",
    nivel: null,
    categoria: null,
    supervisor_ids: null,
    frecuencia: null,
    localidad_id: null,
    localidad_ids: null,
  });

  const { 
    nombre, 
    nivel, 
    categoria, 
    supervisor_ids, 
    frecuencia,
    localidad_id,
    localidad_ids
  } = header.state;

  const schema = useCrudReducer('key')

  const getCategoria = () => categorias?.find(c => c.categoria_id === planilla?.categoria_id)

  useEffect(()=>{
    header.set({
      nombre: planilla?.nombre,
      nivel: planilla?.nivel,
      categoria: getCategoria(),
      supervisor_ids: planilla?.supervisor_ids,
      frecuencia: planilla?.frecuencia,
      localidad_id: planilla?.localidad_id,
      localidad_ids: planilla?.localidad_ids
    })
    schema.set(planilla?.schema ?? [])
  },[planillaIdSelected,supervisores])

  const handleSaveClick = ()=>{
    putPlanilla(planilla?.planilla_id,{
      nombre,
      nivel,
      categoria_id: categoria?.categoria_id,
      frecuencia,
      supervisor_ids,
      schema: schema.state,
      localidad_id,
      localidad_ids,
    })
      .then((data)=>{
        planillas.upsert(data)
        popup.success("Se ha guardado la planilla");
      })
      .catch(()=>{
        popup.error("No se pudo guardar la planilla")
      })
  }

  const consolidada = planilla?.reporte_count > 0

  return (
    <MainContainer>
      {
        planilla ? (
          <PlanillaEditorContext.Provider value={{ 
            planilla,
            header,
            schema,
            consolidada
          }}>
            <Stack spacing={2}>
              {
                consolidada &&
                <Alert elevation={1} variant="filled" severity="warning" icon={<Icon path={mdiTableLock} size={1}/> }>
                  <div><b>Planilla consolidada</b></div>
                  <Box sx={{mt:1}}>
                    Solo puede modificar nombre y supervisores autorizados.&nbsp;
                    <a style={{color:"inherit"}} href={`/reportes?planilla_id=${planilla.planilla_id}`}><b>{ planilla?.reporte_count } reporte(s)</b></a>
                    &nbsp;hacen uso de esta.
                  </Box>
                </Alert>
              }
              
              <Card>
                <CardContent>
                  <Stack spacing={2}>
                    <PlanillaHeader/>
                  </Stack>
                </CardContent>
              </Card>

              <Card>
                <PlanillaSchema/>
              </Card>
              
              {
                ! consolidada &&
                <Alert elevation={1} severity="warning">
                  Al presionar el botón "GUARDAR" usted está consciente que una vez que esta
                  planilla sea usada en para crear un reporte solo podrá usted cambiar 
                  el nombre y supervisores de campo.
                </Alert>
              }

              <Stack direction="row-reverse" >
                <Button
                  variant="contained"
                  startIcon={<Icon path={mdiContentSave} size={1}/>}
                  onClick={handleSaveClick}
                >
                  Guardar
                </Button>
              </Stack>
            </Stack>
          </PlanillaEditorContext.Provider>
        ) : (
          <List>
            <ListItem>
              <ListItemButton disabled>
                <i>Seleccione una planilla para poder editarla</i>
              </ListItemButton>
            </ListItem>
          </List>
        )
      }
    </MainContainer>
  )
}

export default PlanillaDetail;