import { Box } from "@mui/material";
import { Icon } from "@mdi/react";
import { icons } from "../utils/icons";

const NodeIcon = ({type,component='div',sx}) => {
  return <Box component={component} sx={{opacity:0.5,...sx}}>
    <Icon path={ icons[type] } size={1} />
  </Box>
}

export default NodeIcon;