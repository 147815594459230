import { Autocomplete, TextField } from "@mui/material";
import { useEffect } from "react";

const SectorAutocomplete = ({sectores, label,onChange, disabled,...props})=>{
  const isDisabled = disabled != null
    ? disabled
    : !sectores || sectores.length === 0;
  
  useEffect(()=>{
    if(sectores?.length === 1){
      onChange(null, sectores[0])
    }
  },[sectores])

  return <Autocomplete
    {...props}
    onChange={onChange}
    disabled={isDisabled}
    options={sectores}
    autoHighlight
    getOptionLabel={(option) => option.nombre}
    getOptionKey={option=>option.sector_id}
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // disable autocomplete and autofill
        }}
      />
    )}
  />
}

export default SectorAutocomplete;