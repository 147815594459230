import { createContext, useContext, useState, useEffect } from "react";
import axios from "../axios";

const ComunasContext = createContext({
  regiones:[],
  provincias:[],
  comunas:[]
})

export const useComunas = ()=>{
  const {regiones, provincias, comunas} = useContext(ComunasContext);
  
  const findRegionByComunaId = (comuna_id) => {
    return regiones.find(
      r => provincias.find(
        p => comunas.find(
          c => comuna_id === c.comuna_id 
          && c.provincia_id === p.provincia_id
          && p.region_id === r.region_id
        )
      )
    )
  }
  
  const findComunaById = id => comunas.find(comuna=>comuna.comuna_id === id)

  return { 
    regiones, 
    provincias, 
    comunas, 
    findRegionByComunaId, 
    findComunaById 
  }
}

export const ComunasProvider = ({ children }) => {
  const [regiones, setRegiones] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [comunas, setComunas] = useState([]);

  const fetchComunas = () => {
    axios.get('/comuna')
      .then(({data})=>{
        const comunas = data.comunas.map(comuna=>({
          ...comuna,
          provinciaNombre: data.provincias.find(p => p.provincia_id === comuna.provincia_id).nombre
        }))
        setComunas(comunas)
        setProvincias(data.provincias)
        setRegiones(data.regiones)
      })
      .catch(({response})=>{
        console.error(response)
      })
  }
  
  useEffect(()=>{
    fetchComunas()
  },[]);

  return (
    <ComunasContext.Provider value={{ regiones, provincias, comunas, fetchComunas }}>
      {children}
    </ComunasContext.Provider>
  )
}
