import { Box } from "@mui/material";
import { Icon } from "@mdi/react";

const IconInputDecorator = ({ path, disabled }) => {
  return (
    <Box sx={{ pt: 1, opacity: disabled ? 0.5 : 1 }}>
      <Icon path={ path } size={1}/>
    </Box>
  );
}
export default IconInputDecorator;