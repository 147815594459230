import { Autocomplete, TextField } from "@mui/material";
import { useLocalidades } from "../../contexts/LocalidadesProvider"
import { useEffect, useState } from "react";
import { usePlanillas } from "./PlanillasContext";

const hasAncestor = (tree, node, condition)=>{
  const ancestor = tree.find(n=>n.nodeId === node.parentId);
  if(!ancestor) return false;
  return condition(ancestor) || hasAncestor(tree, ancestor, condition);
}

const LocalidadCuartelesAutocomplete = ({
  nivel,
  localidadId,
  onSelect,
  disabled
})=>{

  const { empresaSelected } = usePlanillas()

  const { tree } = useLocalidades();

  const options = tree.filter(option =>
      option.type.toUpperCase() === nivel
      && hasAncestor(tree, option, node => node.type === "empresa" && node.empresa_id === empresaSelected?.empresa_id )
    )

  const innerValue = tree.find(option=> option.localidad_id === localidadId) ?? null

  const findCuartelIds = ( localidad )=>{
    if(localidad.type.toUpperCase() === "CUARTEL"){
      return [ localidad.localidad_id ]
    }

    const children = []
    for(let i = 0; i < tree.length; i++){
      if(tree[i].parentId === localidad.nodeId){
        const x = findCuartelIds(tree[i])
        children.push(x)
      }
    }
    return children.flat();
  }

  const handleChange = (_, newValue) => {
    if(newValue){
      onSelect(
        newValue.localidad_id,
        findCuartelIds(newValue)
      )
    }else{
      onSelect(null, [])
    }
  }

  return <>
    <Autocomplete
      options={options}
      value={innerValue}
      getOptionKey={option=>option.localidad_id}
      getOptionLabel={option=>option.nombre}
      renderInput={(params) => (
        <TextField
          label={"Seleccione " + nivel}
          size="small"
          fullWidth
          {...params}
        />
      )}
      sx={{ flex: 1 }}
      size="small"
      onChange={handleChange}
      disabled={disabled}
    />
  </>
}

export default LocalidadCuartelesAutocomplete