import { useEffect, useId, useRef} from 'react';
import GMapsWrapper from './GMapsWrapper';
import GMapsBaseBox from './GMapsBaseBox';
import { Box, Stack, useTheme } from '@mui/material';
import { useGPS } from '../contexts/GPSProvider';
import { Icon } from '@mdi/react';
import { mdiAccount, mdiMapMarkerOff } from '@mdi/js';
import { renderToString } from "react-dom/server"
import createMdiGlyph from "../utils/createMdiGlyph";

/**
 * @param {object} props
 * @param { [number,number][] } props.polygon
 * @param { string } props.color
 * @param {import('@mui/material/Box').BoxProps} props.props
 */
const GMapsPolygonContent = ({ polygon = [], color="#FFFF00", ...props }) => {
  const maps = window?.google?.maps
  const { lngLatPosition } = useGPS()
  const ref = useRef(null)
  const mapId = useId()

  useEffect(() => {
    const refresh = async () =>{
      if(!polygon || polygon.length === 0 || !maps) return;
      const center =  {
        lng: polygon.reduce((sum, point) => sum + point[0], 0) / polygon.length,
        lat: polygon.reduce((sum, point) => sum + point[1], 0) / polygon.length,
      };

      const map = new maps.Map(ref.current, {
        center,
        mapId,
        mapTypeId: maps.MapTypeId.SATELLITE,
        mapTypeControl: false,
        streetViewControl: false,
        controlSize:24,
        tilt: 0
      });

      const polygonLngLat = polygon.map(([lng,lat])=>({lng,lat}))

      // Construct the polygon.
      const mapPolygon = new maps.Polygon({
        paths: polygonLngLat,
        strokeColor: color,
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: color,
        fillOpacity: 0.2,
      });
      
      mapPolygon.setMap(map);
      

      const lats = polygonLngLat.map(p => p.lat)
      const lngs = polygonLngLat.map(p => p.lng)
      
      map.fitBounds({
        north: Math.max(...lats),
        south: Math.min(...lats),
        east: Math.max(...lngs),
        west: Math.min(...lngs) 
      },16);

      const pin = new window.google.maps.marker.PinElement({
        glyph: createMdiGlyph({path: mdiAccount}),
        glyphColor: "white",
        scale:1.5
      });
    
      if(lngLatPosition){
        const marker = new maps.marker.AdvancedMarkerView({
          position: lngLatPosition,
          map,
          title: "Usted",
          content: pin.element,
        })
      }

    }
 
    refresh();
  },[polygon, color, maps]);

  return <GMapsBaseBox {...props} ref={ref}/>;
};

const GMapsPolygon = GMapsWrapper(GMapsPolygonContent)    

export default GMapsPolygon;
