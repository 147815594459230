import {
  axiosGetFactory,
  axiosGetByIdFactory,
  axiosPostFactory,
  axiosPutByIdFactory,
  axiosDownloadFileFactory,
  axiosDeleteByIdFactory
} from "../axios";

export const getEmpresas = axiosGetFactory('/empresa')
export const getEmpresasXLS = axiosDownloadFileFactory('/empresa/xls')
export const getEmpresaById = axiosGetByIdFactory('/empresa')
export const postEmpresa = axiosPostFactory('/empresa')
export const putEmpresa = axiosPutByIdFactory('/empresa')
export const deleteEmpresaById = axiosDeleteByIdFactory('/empresa')