import {
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Box,
  Tooltip
} from "@mui/material"
import { Icon } from "@mdi/react"
import { mdiContentCopy, mdiDeleteOutline, mdiTableLock } from "@mdi/js";
import { usePlanillas } from "./PlanillasContext";
import { postPlanilla, deletePlanilla } from "../../client/Planilla";
import { useEffect } from "react";
import { usePopup } from "../../contexts/PopupProvider";

const PlanillasList = ({ empresaId })=>{  
  const {
    planillas,
    setPlanillaIdSelected,
    planillaIdSelected
  } = usePlanillas()
  const popup = usePopup();

  useEffect(()=>{
    setPlanillaIdSelected(null)
  },[empresaId])

  const handleCloneButton = (item) => (e) => {
    e.preventDefault();

    const copyMatchPattern = /\((\d+)\)$/
    const copyMatch = item.nombre.match(copyMatchPattern)
    const nextIndex = copyMatch ? parseInt(copyMatch[1], 10) + 1 : 1;

    postPlanilla({
      ...item,
      planilla_id: undefined,
      nombre: item.nombre.replace(copyMatchPattern,"") + `(${nextIndex})`,
    })
      .then((planilla)=>{
        planillas.upsert(planilla)
        setPlanillaIdSelected(planilla.planilla_id)
      })
      .catch(()=>{
        popup.error("No se pudo clonar la planilla. Inténtelo más tarde.")
      })
  }

  const handleDeleteButton = (item) => (e) => {
    e.preventDefault();
    deletePlanilla(item.planilla_id)
      .then(()=>{
        planillas.drop(item);
        setPlanillaIdSelected(null)
      })
      .catch(()=>{
        popup.error("No se pudo eliminar la planilla. Inténtelo más tarde.")
      })
  }

  const handleSelectButton = (item) => (e) => {
    e.preventDefault();
    setPlanillaIdSelected(item.planilla_id)
  }


  return (
    planillas?.state.map((item, index) => (
      <ListItem
        key={index}
        disablePadding
        sx={{
          "&:not(:hover) .optionButton":{
            display: "none"
          },
          "& .optionButton":{
            marginTop: -1,
            marginBottom: -1
          }
        }}
      >
        <ListItemButton selected={item.planilla_id === planillaIdSelected}>
          <ListItemText onClick={handleSelectButton(item,index)}>
            { 
              item.reporte_count > 0 &&
              <Tooltip title="Planilla consolidada" placement="top">
                <Box component="div" sx={{display: 'inline-flex', alignItems:"center", mr:1, opacity:0.5 }}>
                  <Icon path={mdiTableLock} size={2/3}/>
                </Box>
              </Tooltip>
            }

            { item.nombre }
          </ListItemText>
          <IconButton className="optionButton" sx={{mr:-1}} onMouseDown={handleCloneButton(item,index)}>
            <Icon path={mdiContentCopy} size={1}/>
          </IconButton>
          <IconButton className="optionButton" sx={{mr:-1}} onMouseDown={handleDeleteButton(item,index)} disabled={item.reporte_count > 0}>
            <Icon path={mdiDeleteOutline} size={1}/>
          </IconButton>
        </ListItemButton>
      </ListItem>
    ))
  );
}

export default PlanillasList;