
import { IconButton, Stack, Tooltip, alpha } from "@mui/material"
import { Icon } from "@mdi/react"
import DataTable from "../../components/DataTable"
import { mdiHistory, mdiClose, mdiPencil, mdiPlus } from "@mdi/js"
import { REPORTES_COLUMNS } from "./ReportesColumns"
import { icons } from "../../utils/icons"
import { useReportes } from "./ReportesContext"

const ReportesVistaTabla = ({ handleRowEvent})=>{
  const planillaHighlighted = new URLSearchParams(window.location.search ?? '').get('planilla_id')
  const { reportes, planillaId, localidadId} = useReportes();
  
  const data = (
    reportes.filter(f=> 
      ( planillaId == null || f.planilla_id === planillaId ) &&
      ( localidadId == null || f.localidad_id === localidadId )
    )
  )

  return (
    <DataTable
      columns={[
        ...REPORTES_COLUMNS,
        { 
          key: "actions",
          columnProps: {sx:{textAlign:"center", width:64,justifyContent:"center"}},
          Cell: ({row, triggerRowEvent })=> (
            <Stack direction="row" spacing={0}>
              
              <Tooltip placement="left" title={row.estado === 'Pendiente'?"Nuevo reporte": "Editar reporte"}>
                <IconButton color="primary" onClick={()=>triggerRowEvent("NUEVO_REPORTE",row)}>
                  <Icon path={ row.estado === "Pendiente"? mdiPlus: mdiPencil} size={1}/>
                </IconButton>
              </Tooltip>
              {
                planillaId && localidadId
                ? 
                  <Tooltip 
                    placement="left"
                    title="Cerrar histórico"
                  >
                    <IconButton color="error" onClick={()=>triggerRowEvent("CERRAR_HISTORICO",row)}>
                      <Icon path={mdiClose} size={1}/>
                    </IconButton>
                  </Tooltip>
                : 
                  <Tooltip 
                    placement="left"
                    title={row.createdAt ?'Abrir histórico':''}
                  >
                    <IconButton color="primary" onClick={()=>triggerRowEvent("VER_HISTORICO",row)} disabled={!row.createdAt}>
                      <Icon path={mdiHistory} size={1}/>
                    </IconButton>
                  </Tooltip>
              }
            </Stack>
          )
        }
      ]}
      onRowEvent={handleRowEvent}
      data={ data }
      getId={row => row.planilla_id }
      getRowKey={row => row.planilla_id + "-" + row.localidad_id }
      rowProps={(row)=>({
        sx: {
          backgroundColor: theme => Number(planillaHighlighted) === Number(row.planilla_id) 
            ? alpha(theme.palette.warning.main,0.1)
            : undefined
        }
      })}
    />
  );
}

export default ReportesVistaTabla;