import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@emotion/react';
import theme from './theme'
import { RouterProvider } from 'react-router-dom';
import router from './router'
import CssBaseline from '@mui/material/CssBaseline';
import { DrawerProvider } from './contexts/Drawer';
import { UsuarioProvider } from './contexts/UsuarioProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GPSProvider } from './contexts/GPSProvider';
import { PopupProvider } from './contexts/PopupProvider';
import { PipelineProvider } from './contexts/PipelineProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <CssBaseline/>
    <PipelineProvider
      providers={[
        [ ThemeProvider, {theme} ],
        [ LocalizationProvider, {dateAdapter: AdapterDayjs} ],
        [ GPSProvider ],
        [ PopupProvider ],
        [ DrawerProvider ],
      ]}
    >
      <RouterProvider router={router} />
    </PipelineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
