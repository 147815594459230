import { Button } from "@mui/material";
import { getEmpresasXLS } from "../../client/Empresa";
import { mdiDownload } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useLocalidades } from "../../contexts/LocalidadesProvider";

const DownloadXLS = ()=>{
  const { selectedNode, empresas } = useLocalidades();

  const handleDownloadXLS = ()=>{
    getEmpresasXLS({
      empresa_id: selectedNode.empresa_id
    });
  }
  
  return (
    <Button
      variant="text"
      startIcon={<Icon path={mdiDownload} size={1}/>}
      onClick={handleDownloadXLS}
    >
      Descargar Excel
    </Button>
  );
}

export default DownloadXLS;