import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Icon } from '@mdi/react'
import { mdiSprout } from '@mdi/js';

function NotFound() {
  return (
    <Box sx={{
      display:"flex",
      backgroundColor: theme=> theme.palette.grey[100]
    }}>
      <Container 
        component="main" 
        maxWidth="md"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          height: '100vh',
        }}
      >
        <Avatar sx={{ width: 128, height: 128, mb:6}}>
          <Icon path={mdiSprout} size={2}/>
        </Avatar>
        <Typography component="h1" variant="h3" sx={{mb:2}}>
          Contenido no disponible
        </Typography>
        <Typography component="p" variant="body-1">
          El contenido al que intentas acceder no existe o está deshabilitado.
        </Typography>
        <Typography component="p" variant="body-1">
          <Typography component="a" href="/" sx={{color:theme=>theme.palette.primary.light}}>Volver al inicio</Typography>
        </Typography>
      </Container>
    </Box>
  );
}

export default NotFound;