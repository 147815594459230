import { Autocomplete, Box, TextField } from "@mui/material";
import { useComunas } from "../contexts/ComunasProvider"

const RegionAutocomplete = ({...props}) => {
  const { regiones } = useComunas();

  return <Autocomplete
    {...props}
    options={regiones}
    autoHighlight
    getOptionLabel={(option) => option.nombre}
    getOptionKey={option=>option.region_id}
    renderOption={(props, option) => (
      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        <img
          loading="lazy"
          width="24"
          src={option.bandera_url}
          alt=""
        />
        {option.nombre}
      </Box>
    )}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Region"
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // disable autocomplete and autofill
        }}
      />
    )}
  />
}
export default RegionAutocomplete;