import { TreeItem, TreeView } from "@mui/x-tree-view";
import { Box, Button as MuiButton, List, ListItem, Paper, TextField } from "@mui/material";
import NodeIcon from "../../components/NodeIcon"
import { useLocalidades, NodeType } from "../../contexts/LocalidadesProvider";
import { Icon } from "@mdi/react";
import { icons } from "../../utils/icons";
import { postEmpresa } from "../../client/Empresa";
import { postCampo } from "../../client/Campo";
import { postCuartel } from "../../client/Cuartel";
import { postSector } from "../../client/Sector";
import { useUsuario } from "../../contexts/UsuarioProvider";
import { usePopup } from "../../contexts/PopupProvider";

const getNodeLabel = (node)=>{
  return <Box sx={{display:"flex", gap:2, minHeight:40, alignItems:"center"}}>
    <NodeIcon type={node.type}/>
    { node.nombre }
  </Box>
}

const Button = ({icon,children,...props})=>{
  return <MuiButton {...props} size="small" variant="outlined" startIcon={<Icon path={icon} size={2/3}/>} sx={{flex:1}}>{children}</MuiButton> 
}

const TreeViewDrawer = () => {
  const { usuario } = useUsuario();
  const popup = usePopup()
  
  const createButtons = usuario.tipo === "PROPIETARIO"

  const localidades = useLocalidades()

  const selectedNode = localidades.selectedNode;


  // DEBE SER UN COMPONENTE RECURSIVO
  const generateTree = (parentId = null) => {
    return localidades.tree?.filter(x=>x.parentId===parentId).map(item=>(
      <TreeItem
        key={item.nodeId}
        label={ getNodeLabel(item) }
        nodeId={item.nodeId}
      >
        { generateTree(item.nodeId) }
      </TreeItem>
    ))
  }

  /**
   * 
   * @param {*} postFunction Función que llama a la API para crear un nuevo registro
   * @param {*} nombre nombre por defecto del nuevo nodo
   * @param {*} type tipo de nodo NodeType.EMPRESA, NodeType.CAMPO, NodeType.SECTOR o NodeType.CUARTEL
   * @param {*} extra información adicional al nuevo registro
   * @param {*} upsertFn función "upsert" obtenida desde localidades.empresas, etc.
   * @param {*} idKey clave para identificar el id del nuevo registro
   * @returns 
   */
  const postFactory = (postFunction, nombre, type, extra, upsertFn, idKey) => ()=>{
    postFunction({
      nombre,
      ...extra(selectedNode)
    })
      .then(data=>{
        upsertFn(data)
        setTimeout(()=>{
          localidades.setSelectedNodeId(type + "-" + data[idKey])
        }, 50)
      })
      .catch(err=>{
        console.error(err)
        popup.error(`Error al crear ${type} "${nombre}"`)
      })
  }

  const handleNuevaEmpresaClick = postFactory(
    postEmpresa, 
    "Nueva empresa",
    NodeType.EMPRESA,
    ()=>({}),
    localidades.empresas.upsert,
    "empresa_id"
  )
  const handleNuevoCampoClick = postFactory(
    postCampo,
    "Nuevo campo",
    NodeType.CAMPO,
    node=>({empresa_id: node.empresa_id,kmlfile:""}),
    localidades.campos.upsert,
    "campo_id"
  )
  const handleNuevoSectorClick = postFactory(
    postSector,
    "Nuevo sector",
    NodeType.SECTOR,
    node=>({campo_id: node.campo_id,kmlfile:""}),
    localidades.sectores.upsert,
    "sector_id"
  )
  const handleNuevoCuartelClick = postFactory(
    postCuartel,
    "Nuevo cuartel",
    NodeType.CUARTEL,
    node=>({sector_id: node.sector_id,kmlfile:""}),
    localidades.cuarteles.upsert,
    "cuartel_id"
  )

  return (
    <Paper sx={{width:260,minWidth:260, display:"flex", flexDirection:"column"}}>
      <List>
        {/*<ListItem>
          <TextField fullWidth size="small" placeholder="Buscar..."/>
        </ListItem>*/}
        {
          usuario.tipo === 'SYSADMIN' && <>
            <ListItem sx={{display:"flex"}}>
              <Button icon={icons.empresa} onClick={handleNuevaEmpresaClick}>Nueva Empresa</Button>
            </ListItem>
          </>
        }
        { 
          [ 'PROPIETARIO', 'SYSADMIN' ].includes(usuario.tipo) && <>
            <ListItem sx={{display:"flex"}}>
              {{
                [ NodeType.EMPRESA ]:
                  <Button icon={icons.campo} onClick={handleNuevoCampoClick}>Nuevo Campo</Button>,
                [ NodeType.CAMPO ]:
                  <Button icon={icons.sector} onClick={handleNuevoSectorClick}>Nuevo Sector</Button>,
                [ NodeType.SECTOR ]:
                  <Button icon={icons.cuartel} onClick={handleNuevoCuartelClick}>Nuevo Cuartel</Button>,
              }[selectedNode?.type]}
              {
                (!selectedNode || selectedNode?.type === NodeType.CUARTEL)
                && <Button icon={icons.localidades} disabled>Nuevo Subnivel</Button>
              }
            </ListItem>      
          </>
        }
      </List>
      
      <Box sx={{flex:1, overflow:"auto"}}>
        <TreeView 
          expanded={ localidades.tree?.map(x=>x.nodeId) }
          onNodeToggle={()=>{}}
          selected={ [ selectedNode?.nodeId ] }
          onNodeSelect={ (_,v)=>{console.log(v);localidades.setSelectedNodeId(v)} }
        >
          { generateTree() }
        </TreeView>
      </Box>
    </Paper>
  )
}

export default TreeViewDrawer;