import { Autocomplete, TextField } from "@mui/material"

const CategoriaAutocomplete = ({categorias, value, onChange, disabled,...props})=>{
  return (
    <Autocomplete
      options={categorias??[]}
      getOptionKey={option=>option.categoria_id}
      getOptionLabel={option=>option.nombre}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Categoría"
          size="small"
        />
      )}
      sx={{flex:1}}
      value={value ?? null}
      onChange={(_,v)=>{onChange && onChange(v)}}
      isOptionEqualToValue={(o,v) => o.categoria_id === v?.categoria_id}
      disabled={disabled}
      {...props}
    />
  );
}

export default CategoriaAutocomplete;