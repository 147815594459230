import { IconButton, TableCell, TableRow, TextField, Button, Alert, Checkbox } from "@mui/material"
import { Icon } from "@mdi/react"
import DataTypeSelector from "./DataTypeSelector"
import { mdiDelete, mdiPlus } from "@mdi/js"
import { useEffect, useState } from "react"
import DataTypeEnum from "./DataTypeEnum"
import DataTypeInt from "./DataTypeInt"
import DataTypeDecimal from "./DataTypeDecimal"
import TrimmedTextField from "../../components/TrimmedTextField"

const useSync = (set,value)=>{
  useEffect(()=>{
    set(value)
  },[value])
}

const PropiedadRow = ({ propKey, onDeleteClick, onAddClick, value, onChange, disabled }) => {
  value ??= {}

  const {
    nombre: initialNombre = "",
    type: initialType = null,
    allowNull: initialAllowNull = false,
    ...initialOptions
  } = value

  const [ nombre, setNombre ] =  useState(initialNombre)
  const [ type, setType ] =  useState(initialType)
  const [ allowNull, setAllowNull ] =  useState(initialAllowNull)
  const [ options, setOptions ] = useState(initialOptions)
  
  const exportData = ()=>({
    nombre,
    type,
    allowNull,
    ...options,
    key: propKey
  })

  const handleDeleteClick = () => {
    onDeleteClick && onDeleteClick(exportData())
  }
  
  const handleAddClick = () => {
    onAddClick && onAddClick(exportData())
  }

  useSync(setNombre,initialNombre)
  useSync(setType,initialType)
  useSync(setAllowNull,initialAllowNull)
  useSync(setOptions,options)

  useEffect(()=>{
    onChange && onChange(exportData())
  },[nombre, type, allowNull,options])
  
  const OptionsComponent = {
    int: DataTypeInt,
    decimal: DataTypeDecimal,
    text: ()=>null,
    boolean: ()=>null,
    enum: DataTypeEnum,
    date: ()=>null,
    time: ()=>null,
  }[type] ?? (()=>null)

  return (
    <TableRow sx={{verticalAlign:"top"}}>
      <TableCell>
        <TrimmedTextField
          value={nombre}
          onChange={(e)=>setNombre(e.target.value)}
          fullWidth
          size="small"
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        <DataTypeSelector
          value={type}
          onChange={setType}
          disabled={disabled}
        />
      </TableCell>
      <TableCell sx={{textAlign:"center"}}>
        <Checkbox
          checked={allowNull} 
          disabled={disabled}
          onChange={(e)=>setAllowNull(e.target.checked)}
        />
      </TableCell>
      <TableCell>
        <OptionsComponent 
          type={type}
          value={options}
          onChange={setOptions}
          size="small"
          fullWidth
          disabled={disabled}
        />
      </TableCell>
      {
        !disabled && (
          <TableCell>
            {
              onDeleteClick && (
                <IconButton size="small" onClick={handleDeleteClick}>
                  <Icon path={mdiDelete} size={1}/>
                </IconButton>
              )
            }
            {
              onAddClick && (
                <Button variant="contained" onClick={handleAddClick} disabled={!type || !nombre}>
                  <Icon path={mdiPlus} size={1}/>
                </Button>
              )
            }
          </TableCell>
        )
      }
    </TableRow>
  )
}

export default PropiedadRow;