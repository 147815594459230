import { mdiSprout } from "@mdi/js"
import { icons } from "../../utils/icons"
import { ListItem, ListItemText, Avatar, alpha, Typography } from "@mui/material";
import { Icon } from "@mdi/react"
import { useUsuario } from "../../contexts/UsuarioProvider"

const UsuarioDrawerItem = ()=>{
  const { usuario } = useUsuario();
  
  return (
    
    <ListItem sx={{ml:-0.5}}>
      <Avatar sx={{ width: 40, height: 40, backgroundColor: theme=>alpha(theme.palette.secondary.main,0.2),mr:2 }}>
        <Icon path={icons[usuario.tipo?.toLocaleLowerCase()] ?? mdiSprout} size={1}/>
      </Avatar>
      <ListItemText 
        primary={usuario.nombres + " " + usuario.apellidos}
      />
    </ListItem>
  )
}

export default UsuarioDrawerItem