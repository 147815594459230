import { TableContainer,Table,TableHead,TableRow,TableCell,TableBody, Card, ListItemButton, ListItem, ListItemText, Stack, Typography, getPopoverUtilityClass } from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import { useContext } from "react";
import { postSupervisorSectores } from "../../client/Supervisor";
import { SupervisoresContext } from "../../contexts/SupervisoresProvider";
import { usePopup } from "../../contexts/PopupProvider";

const SupervisoresTable = ({ empresaId, campoId }) => {
  const { supervisores, sectores, supervisorSectores } = useContext(SupervisoresContext);
  
  const popup = usePopup()

  const handleUpdateSectores = (supervisor) => (_,sectores)=>{
    postSupervisorSectores({
      usuario_id: supervisor.usuario_id,
      sector_ids: sectores.map(sector=>sector.sector_id),
      campo_id: campoId,
      empresa_id: empresaId
    })
      .then(()=>{
        supervisorSectores.update({ usuarioId: supervisor.usuario_id, sectores })
      })
      .catch(()=>{
        popup.error(`No se pudo actualizar sector para ${supervisor.nombres} ${supervisor.apellidos}`)
      })
  }
  
  const filteredSupervisores = campoId
    ? supervisores.state.filter(
        supervisor => supervisor.campo_id === campoId
      )
    : supervisores.state
  
  return (
    <TableContainer component={Card}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">  
        <TableHead>
          <TableRow>
            <TableCell sx={{ maxWidth: 160, width:160 }}><b>Supervisor</b></TableCell>
            <TableCell><b>Rut</b></TableCell>
            <TableCell sx={{ maxWidth: 128, width:128 }}><b>Planillas Asignadas</b></TableCell>
            <TableCell><b>Sectores</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { 
            ! campoId
            ? (
              <TableRow>
                <TableCell colSpan="2" sx={{m:0,p:0}}> 
                  <ListItem disablePadding>
                    <ListItemButton disabled>
                      Seleccione un campo
                    </ListItemButton>
                  </ListItem>
                </TableCell>
              </TableRow>
            ) :
            filteredSupervisores.length ===0
            ? (
              <TableRow>
                <TableCell colSpan="2" sx={{m:0,p:0}}> 
                  <ListItem disablePadding>
                    <ListItemButton disabled>
                      Sin información
                    </ListItemButton>
                  </ListItem>
                </TableCell>
              </TableRow>
            ) : (
              filteredSupervisores.map((row) => (
              <TableRow key={row.usuario_id}>
                <TableCell component="td">
                  <div>{row.nombres + " " + row.apellidos}</div>
                </TableCell>
                <TableCell>
                  <div>{row.rut}</div>
                </TableCell>
                <TableCell>
                  <div>{row.planillas_count}</div>
                </TableCell>

                <TableCell component="td">
                  <Autocomplete
                    multiple
                    options={sectores.state.filter(sector=>sector.campo_id === campoId)}
                    getOptionLabel={(option) => option.nombre}
                    value={supervisorSectores.value[row.usuario_id]}
                    size="small"
                    onChange={handleUpdateSectores(row)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                      />
                    )}
                    isOptionEqualToValue={(option, value) => option.sector_id === value.sector_id}
                  />
                </TableCell>
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SupervisoresTable;