import { useReducer } from "react"

const usuarioSectoresReducer = (state,action) => {
  const actions = {
    SEED(_,{usuarios,sectores}){
      const newState = {}
      usuarios.forEach(usuario=>{
        newState[usuario.usuario_id] = sectores.filter(s => usuario.sector_ids.some(s2 => s2 === s.sector_id))
      })
      return newState
    },
    UPDATE(state,{usuarioId,sectores}){
      const newState = {...state}
      newState[usuarioId] = sectores;
      return newState
    }
  }

  return actions[action.type]?.(state,action)
}

const useUsuarioSectores = () => {
  const [ value, dispatch ] = useReducer(usuarioSectoresReducer,{})

  return {
    /** @type {Record<number,Array<number>>} */
    value,
    seed: ({usuarios, sectores}) => dispatch({type: "SEED", usuarios, sectores }),
    update: ({usuarioId, sectores}) => dispatch({type: "UPDATE", usuarioId, sectores})
  }
}

export default useUsuarioSectores;