import { createContext, useContext, useEffect } from "react"
import { useCrudReducer } from "../hooks/useCrudReducer"
import { getSupervisores } from "../client/Supervisor";
import useUsuarioSectores from "../hooks/useUsuarioSectores";
import { getSectores } from "../client/Sector";

export const SupervisoresContext = createContext({
  /** @type { ReturnType<useCrudReducer> } */
  supervisores: null,
  /** @type { ReturnType<useCrudReducer> } */
  sectores: null,
  supervisorSectores: {
    value: null,
    seed: ({usuarios, sectores}) => {},
    update: ({usuarioId, value}) => {}
  },
})

export const useSupervisores = () => useContext(SupervisoresContext)

export const SupervisoresProvider = ({ campoId, empresaId, children })=>{
  const sectores = useCrudReducer('sector_id');
  const supervisores = useCrudReducer('usuario_id');
  const supervisorSectores = useUsuarioSectores();

  useEffect(()=>{
    getSectores()
      .then((data)=>{
        sectores.set(data)
      })
      .catch(()=>{})
  },[])

  useEffect(()=>{
    if(!empresaId) return 
    getSupervisores({empresa_id: empresaId})
      .then((data)=>{
        supervisores.set(data)
        supervisorSectores.seed({
          usuarios: data,
          sectores: sectores.state,
        })
      })
      .catch(()=>{})
  },[empresaId])


  return (
    <SupervisoresContext.Provider value={{
      supervisores,
      sectores,
      supervisorSectores
    }}>
      { children }
    </SupervisoresContext.Provider>
  );
}