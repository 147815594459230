import { Geodesic, PolygonArea } from "geographiclib-geodesic";

/**
 * Calculates the area of a polygon on the Earth's surface using GeographicLib.
 * @param {Coordinates[]} vertices - Array containing the vertices of the polygon represented as [longitude, latitude] in degrees.
 * @returns {number} Area of the polygon in square meters.
 */
export function calculateSphericPolygonArea(vertices) {
  if(!vertices) return 0;

  // Create a Geodesic object for WGS84 ellipsoid
  const geod = Geodesic.WGS84;

  const polygon = new PolygonArea.PolygonArea(geod);
  
  vertices.forEach(v=>{
    polygon.AddPoint(v[1], v[0]);
  })

  // Compute the area of the polygon
  const { area } = polygon.Compute(false, true); // Do not include areas, accumulate

  return Math.abs(area); // s12 contains the accumulated area
}


export function distance(point1, point2) {
  const [lng1, lat1] = point1;
  const [lng2, lat2] = point2;
  const R = 6371e3; // Earth radius in meters
  const phi1 = lat1 * Math.PI / 180; // latitude in radians
  const phi2 = lat2 * Math.PI / 180;
  const deltaPhi = (lat2 - lat1) * Math.PI / 180;
  const deltaLambda = (lng2 - lng1) * Math.PI / 180;

  const a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
      Math.cos(phi1) * Math.cos(phi2) *
      Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in meters
}

export function pointInPolygon(polygon, target) {
  const [x, y] = target;
  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0], yi = polygon[i][1];
      const xj = polygon[j][0], yj = polygon[j][1];

      const intersect = ((yi > y) !== (yj > y)) &&
          (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
  }

  return inside;
}


export function distanceToPolygon(polygon, targetPosition) {
  let minDistance = Number.MAX_SAFE_INTEGER;

  // Iterate through each edge of the polygon
  for (let i = 0; i < polygon.length; i++) {
    const p1 = polygon[i];
    const p2 = polygon[(i + 1) % polygon.length]; // Next vertex in the polygon

    // Calculate the closest point on the edge to the target position
    const closestPoint = closestPointOnSegment(p1, p2, targetPosition);

    // Calculate the distance between the target position and the closest point
    const d = distance(targetPosition, closestPoint);

    // Update minDistance if the calculated distance is smaller
    if (d < minDistance) {
      minDistance = d;
    }
  }

  return minDistance;
}


// Function to calculate the closest point on a line segment to a given point
export function closestPointOnSegment(p1, p2, targetPosition) {
  const [x1, y1] = p1;
  const [x2, y2] = p2;
  const [x, y] = targetPosition;

  const dx = x2 - x1;
  const dy = y2 - y1;

  const t = ((x - x1) * dx + (y - y1) * dy) / (dx * dx + dy * dy);

  if (t <= 0) {
    return [x1, y1];
  } else if (t >= 1) {
    return [x2, y2];
  } else {
    const closestX = x1 + t * dx;
    const closestY = y1 + t * dy;
    return [closestX, closestY];
  }
}

export function findNearestPolygonIndex(polygons, targetPosition) {
  let minDistance = Number.MAX_SAFE_INTEGER;
  let nearestIndex = -1;

  const MIN_DISTANCE = 2000 // meters

  for(let i = 0; i < polygons.length; i++) {
    const polygon = polygons[i];

    if(!polygon){
      continue;
    }
    
    if(pointInPolygon(polygon, targetPosition)){
      return i;
    }

    const distance = distanceToPolygon(polygon, targetPosition);
    console.log(i, distance)
    if (distance > MIN_DISTANCE) {
      continue;
    }
    if (distance < minDistance) {
      minDistance = distance;
      nearestIndex = i;
    }
  };

  return nearestIndex;
}