import { Autocomplete, Paper, TextField } from "@mui/material";
import { useReportes } from "./ReportesContext"
import { Icon } from "@mdi/react";
import { icons } from "../../utils/icons";

const ReportesPlanillaSelector = () => {
  const {reportes = [], planillaId, setPlanillaId } = useReportes()

  const keys = new Set()
  const options = []
  for(let i = 0; i < reportes.length; i++){
    const planilla = reportes[i]
    if(!keys.has(planilla.planilla_id)){
      options.push({
        nombre: planilla.nombre,
        planilla_id: planilla.planilla_id
      })
    }
    keys.add(planilla.planilla_id)
  } 

  const innerValue = planillaId == null ? null : options.find(opt => planillaId != null && opt?.planilla_id === planillaId)

  const handleChange = (_,v)=>{
    setPlanillaId(v?.planilla_id ?? null)
  }

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.nombre}
      size="small"
      renderInput={(props)=>(
        <Paper>
          <TextField
            sx={{width:360}}
            {...props}
            InputProps={{
              ...props.InputProps,
              startAdornment: <Icon path={icons.planilla} size={1}/>
            }}
          />
        </Paper>
      )}
      value={innerValue}
      onChange={handleChange}
    />
  )
}

export default ReportesPlanillaSelector;