import React, { useEffect, useState } from "react";
import { postVariedad } from "../../client/Variedad"; // Asumiendo que tienes funciones para obtener y crear variedades
import { Card, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import AddItemBox from "../../components/AddItemBox";
import { useCultivosVariedades } from "./CultivosVariedadesContext";
import { usePopup } from "../../contexts/PopupProvider";

const VariedadesPanel = ({ cultivoId, value, onChange }) => {
  const { variedades } = useCultivosVariedades();
  const [ nombre, setNombre ] = useState("");
  const popup = usePopup()

  const filteredVariedades = variedades.state.filter(v=> v.cultivo_id === cultivoId);

  useEffect(()=>{
    onChange(null)
    setNombre("")
  },[cultivoId])

  const handleSubmit = (nombre) => {
    postVariedad({ nombre, cultivo_id: cultivoId })
      .then((variedad)=>{
        onChange(variedad)
        setNombre("")
        variedades.upsert(variedad)
      })
      .catch(() => { 
        popup.error("No se pudo agregar la variedad. Inténtelo más tarde")
      });
  };

  return (
    <>
      <Typography variant="h5">
        Variedades
      </Typography>
      <Card>
        <List>
          {
            filteredVariedades?.length > 0 
            ?
            filteredVariedades.map((variedad) => (
              <ListItem disablePadding key={variedad.variedad_id}>
                <ListItemButton selected={variedad?.variedad_id === value?.variedad_id} onClick={()=>onChange(variedad)}>
                  <ListItemText>
                    {variedad.nombre}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))
            :
            null
          }
          <AddItemBox
            value={nombre}
            disabled={!cultivoId}
            onSubmit={handleSubmit}
            label={
              cultivoId
              ? "Nueva variedad"
              : "Seleccione un cultivo"
            }
          />
        </List>
      </Card>
    </>
  );
};

export default VariedadesPanel;
