import { useState, useContext } from "react";
import { Icon } from "@mdi/react";
import { Button, Card, CardContent, Stack, TextField } from "@mui/material";
import { postSupervisor } from "../../client/Supervisor";
import { icons } from "../../utils/icons";
import { SupervisoresContext } from "../../contexts/SupervisoresProvider";
import { usePopup } from "../../contexts/PopupProvider";

const AgregarSupervisor = ({empresaId, campoId})=>{
  const { supervisores } = useContext(SupervisoresContext);
  const [ nombres, setNombres] = useState("")
  const [ apellidos, setApellidos ] = useState("") 
  const [ rut, setRut ] = useState("")
  const [ email, setEmail ] = useState("")
  const popup = usePopup()

  const handleSaveClick = (e)=>{
    
    e.stopPropagation();

    postSupervisor({
      nombres,
      apellidos,
      rut,
      email,
      empresa_id: empresaId,
      campo_id: campoId
    })
      .then(supervisor=>{
        console.log(supervisor)
        supervisores.upsert({
          ...supervisor.Usuario,
          planillas_count: 0,
          sector_ids: [],
          campo_id: campoId
        })
      })
      .catch(()=>{
        popup.error(`No se pudo agregar el supervisor de campo`)
      })
  }

  return (
    <Card sx={{mb:1}}>
      <CardContent>
        <Stack direction="row" spacing={2}>
          <TextField sx={{flex:1}} size="small" disabled={!campoId} label="Nombres" value={nombres} onChange={(e)=>setNombres(e.target.value)}/>
          <TextField sx={{flex:1}} size="small" disabled={!campoId} label="Apellidos" value={apellidos} onChange={(e)=>setApellidos(e.target.value)}/>
          <TextField sx={{flex:1}} size="small" disabled={!campoId} label="Rut" value={rut} onChange={(e)=>setRut(e.target.value)}/>
          <TextField sx={{flex:1}} size="small" disabled={!campoId} label="Email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
          <Button variant="contained" disabled={!campoId} startIcon={<Icon path={icons.supervisor} size={1}/>} onClick={handleSaveClick}>
            Añadir
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
export default AgregarSupervisor;