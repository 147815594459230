import { useState } from 'react';

function useLocalStorage(key, defaultValue) {
  const [state, setState] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? storedValue : defaultValue;
  });

  const setLocalStorageState = (newValue) => {
    setState(newValue);
    localStorage.setItem(key, newValue);
  };

  return [state, setLocalStorageState];
}

export default useLocalStorage;