import { Autocomplete, TextField } from "@mui/material";

const NivelAutocomplete = ({ value, onChange, disabled })  =>{
  return (
    <Autocomplete
      options={["CAMPO","SECTOR","CUARTEL"]}
      label="Label"
      sx={{flex:1}}
      getOptionKey={x=>x}
      getOptionLabel={x=>x}
      renderInput={params=> (
        <TextField
          {...params}
          label="Nivel"
          size="small"
        />
      )}
      value={value??null}
      onChange={(_,v)=>onChange(v)}
      isOptionEqualToValue={(option,value) => !value || value === option}
      disabled={disabled}
    />
  )
}

export default NivelAutocomplete;