import React, { useId } from 'react';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem } from '@mui/material';

const Select = ({ value, onChange, options, label, ...props }) => {
  const labelId = useId();

  const innerOptions = options.map(option => 
    typeof option === 'string'
    ? ({label: option, value: option}) 
    : ({label: option.label,value: option.value})
  )

  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <MuiSelect
        {...props}
        labelId={labelId}
        value={value ?? null}
        onChange={handleChange}
        label={label}
      >
        {innerOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;