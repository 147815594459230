import { useState, useEffect, useReducer } from "react";
import EmpresaSearchPanel from "../../components/EmpresaSearchPanel";
import JefesTable from "./JefesTable"
import AgregarJefe from "./AgregarJefe";
import MainContainer from "../../components/MainContainer";
import BaseBox from "../../components/BaseBox";
import { JefesContext } from "./JefesContext";
import { useCrudReducer } from "../../hooks/useCrudReducer"
import { getJefes } from "../../client/Jefe";
import { getCampos } from "../../client/Campo";
import NavDrawer from "../../components/Drawer/NavDrawer";
import useUsuarioCampos from "../../hooks/useUsuarioCampos";

const Jefes = ()=>{
  const [ empresaId, setEmpresaId ] = useState(null)
  const campos = useCrudReducer('campo_id');
  const jefes = useCrudReducer('usuario_id');
  const jefeCampos = useUsuarioCampos();

  useEffect(()=>{
    getCampos()
      .then((data)=>{
        campos.set(data)
      })
      .catch(()=>{})
  },[])

  useEffect(()=>{
    if(empresaId){
      getJefes({empresa_id:empresaId})
        .then((data)=>{
          jefes.set(data)
          jefeCampos.seed({
            usuarios: data,
            campos: campos.state,
          })
        })
        .catch(()=>{})
    }
  },[empresaId])
  
  return <BaseBox>
    <NavDrawer/>
    <JefesContext.Provider value={{
      jefes,
      campos,
      jefeCampos
    }}>
      <MainContainer>
        <EmpresaSearchPanel onSelect={setEmpresaId}/>
        <AgregarJefe empresaId={empresaId}/>
        <JefesTable empresaId={empresaId}/>
      </MainContainer>
    </JefesContext.Provider>
  </BaseBox>
}

export default Jefes;