import { Box, Card, CardContent, Toolbar, Typography } from "@mui/material"
import NodeIcon from "../../components/NodeIcon"

const LocalidadCard = ({ node,children })=>{
  return (
  
    <Card>
      <Toolbar>
        <NodeIcon type={node.type} component="span" sx={{mr:2,opacity:1}}/>
        <Typography variant="h5">
          { node.nombre }
        </Typography>
        <Box sx={{flex:1}}/>
        <Typography variant="caption">{ node.type.toLocaleUpperCase() }</Typography>
      </Toolbar>
      <CardContent>
        { children }
      </CardContent>
    </Card>
  )
}

export default LocalidadCard;