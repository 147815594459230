import { useEffect, useState } from "react";
import { TextField, Chip, Typography, Stack, Box } from "@mui/material";
import { Icon } from "@mdi/react";
import { mdiComma, mdiSquareMedium, mdiSquareSmall, mdiSubdirectoryArrowLeft } from "@mdi/js";

function DataTypeEnum ({ value, onChange, disabled, ...props}) {
  const [ options, setOptions ] = useState(value?.enum ?? [])
  const [ inputValue, setInputValue ] = useState('');
  
  useEffect(()=>{
    setOptions(value?.enum ?? [])
  },[value?.enum])

  const handleChange = (tags) =>{
    onChange({
      enum: [... new Set(tags)]
    })
  }

  const PUNCT_SIGNS = /[,.;]/g

  const handleInputKeyPress = (event) => {
    const newItem = inputValue.trim().replace(/\s+/g, ' ')
    if (event.key === 'Enter' && newItem !== '') {
      handleChange([...options, newItem]);
      setInputValue('');
    } else if (PUNCT_SIGNS.test(event.key) && newItem !== '') {
      console.log("NI",newItem)
      handleChange([...options, newItem]);
      setInputValue('');
    }else{
      setInputValue(event.target.value);
    }
  };
  
  const handleInputChange = (event) => {
    setInputValue(event.target.value.replace(PUNCT_SIGNS,""));
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Backspace' && inputValue.trim() === '') {
      handleChange(options.slice(0, options.length - 1));
    }
  }

  const handleDeleteTag = (tagToDelete) => {
    handleChange(options.filter(tag => tag !== tagToDelete));
  };

  return <>
    <TextField
      {...props}
      helperText={ (options?.length === 0) && (
        <Stack component="span" direction="row" spacing={1} sx={{alignItems:"center",flexWrap:"wrap"}}>
          <span>Presione coma</span>
          <Icon path={mdiComma} size={1/3}/>
          <span>para agregar opciones</span>
        </Stack>
      )}
      InputProps={{
        sx:{display:"flex", flexWrap:"wrap", "& input":{minWidth:"64px", flex:1}},

        startAdornment: 
          options?.map((tag, index) => (
            <Chip
              size="small"
              key={index}
              label={tag}
              onDelete={() => !disabled && handleDeleteTag(tag)}
              style={{ marginRight: '5px', marginBottom: '5px' }}
            />
          ))
        ,
        value: inputValue,
        onChangeCapture: handleInputChange,
        onKeyPress: handleInputKeyPress,
        onKeyDown: handleInputKeyDown
      }}
    disabled={disabled}
    />
  </>;
}

export default DataTypeEnum
