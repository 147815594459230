import { Card, ListItem, ListItemButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useCultivosVariedades } from "./CultivosVariedadesContext"

const CultivosVariedadesTable = ({sectorId}) => {  
  const { cultivos, variedades } =  useCultivosVariedades()

  const filteredCultivos = cultivos.state.filter(cultivo => cultivo.sector_id === sectorId)

  const filteredVariedades = filteredCultivos.map(cultivo => {
    const variedad = variedades.state.find(variedad => variedad.cultivo_id === cultivo.cultivo_id);
    return { ...variedad, cultivo };
  });

  return (
    <TableContainer component={Card}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">  
        <TableHead>
          <TableRow>
            <TableCell><b>Cultivo</b></TableCell>
            <TableCell><b>Variedad</b></TableCell>
            <TableCell><b>Año Plantación</b></TableCell>
            <TableCell><b>N Plantas</b></TableCell>
            <TableCell><b>Dist Sobre Hilera</b></TableCell>
            <TableCell><b>Dist Entre Hilera</b></TableCell>
            <TableCell><b>Portainjerto</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { 
            !sectorId && (
              <TableRow>
                <TableCell colSpan="2" sx={{m:0,p:0}}> 
                  <ListItem disablePadding>
                    <ListItemButton disabled>
                      Seleccione una empresa
                    </ListItemButton>
                  </ListItem>
                </TableCell>
              </TableRow>
            )
          }
          { 
            filteredVariedades.map((variedad) => (
              <TableRow key={`${variedad.cultivo.cultivo_id}-${variedad.variedad_id}`}>
                <TableCell> 
                  {variedad.cultivo.nombre}
                </TableCell>
                <TableCell>
                  { variedad.nombre } 
                </TableCell>
                <TableCell>
                  { variedad.año_plantacion } 
                </TableCell>
                <TableCell>
                  { variedad.n_plantas }  
                </TableCell>
                <TableCell>
                  { variedad.dist_sobre_hilera }  
                </TableCell>
                <TableCell>
                  { variedad.dist_entre_hilera }  
                </TableCell>
                <TableCell>
                  { variedad.portainjerto }  
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default CultivosVariedadesTable;