import { useEffect, useId, useRef, useState } from "react";
import GMapsWrapper from "../../components/GMapsWrapper";
import GMapsBaseBox from "../../components/GMapsBaseBox";
import { useLocalidades } from "../../contexts/LocalidadesProvider";
import { icons } from "../../utils/icons";
import { mdiMapMarkerAlert } from "@mdi/js";
import { Alert, alpha, Box } from "@mui/material";
import { useReportes } from "./ReportesContext";
import { Icon } from "@mdi/react";
import { useGPS } from "../../contexts/GPSProvider";
import { usePlanillas } from "../Planillas/PlanillasContext";
import { useTheme } from "@emotion/react";

const matrixToLngLat = (polygon)=>polygon.map(([lng,lat])=>({lng,lat}))
const calculateCenter = (polygon)=>({
  lng: polygon.reduce((p,[lng,_])=>p+lng,0)/polygon.length, 
  lat: polygon.reduce((p,[_,lat])=>p+lat,0)/polygon.length
})

const ReportesVistaMapaContent = () => {
  const theme = useTheme();
  const maps = window?.google?.maps;
  const { lngLatPosition } = useGPS()
  const ref = useRef(null)
  const mapId = useId()
  
  const { tree } = useLocalidades()
  const { planillaId, reportes, localidadId, setLocalidadId } = useReportes();
  const { planillas } = usePlanillas();

  const reportesSelected = reportes?.filter(p => p.planilla_id === planillaId) ?? []

  const planillaSelected = reportesSelected?.length ? planillas.state?.find(p => p.planilla_id === reportesSelected[0].planilla_id) : null

  const localidades = planillaSelected ? tree.filter(loc => planillaSelected.localidad_ids.includes(loc.localidad_id)) : null
  
  const calculateBounds = () => {
    if (!localidades) return { north: 60, south: -60, west: -60, east: 60 };
  
    const points = localidades.reduce((acc, loc) => {
      if (!loc.polygon) return acc;
      return acc.concat(loc.polygon);
    }, []);
  
    const lngs = points.map(p => p[0]);
    const lats = points.map(p => p[1]);
  
    return {
      north: Math.max(...lats),
      south: Math.min(...lats),
      west: Math.min(...lngs),
      east: Math.max(...lngs)
    };
  };

  const bounds = calculateBounds()

  const localidadesWithMissingPolygon = localidades?.filter(l => !l.polygon?.length)

  useEffect(()=>{
    const refresh = async ()=>{
      if(!maps || !bounds || !localidades) return;
      
      if(!reportesSelected.length) return;
      
      const map = new maps.Map(ref.current, {
        center: lngLatPosition,
        mapId,
        mapTypeId: maps.MapTypeId.SATELLITE,
        mapTypeControl: false,
        streetViewControl: false,
        controlSize: 24,
        tilt: 0
      });

      map.fitBounds(bounds)

      localidades.forEach((l)=>{
        if(!l.polygon) return;
        
        const reportes = reportesSelected.filter(r=> r.localidad_id === l.localidad_id)
        
        const total = reportes.length
        const completed = reportes.filter( r=> r.supervisor )?.length
        
        const color = total === completed ? theme.palette.secondary : theme.palette.warning

        const mPolygon = new maps.Polygon({
          paths: matrixToLngLat(l.polygon),
          strokeColor: color.main,
          strokeOpacity: 1,
          strokeWeight: 4,
          fillColor: color.main,
          fillOpacity: 0.4,
        })

        mPolygon.setMap(map)

        const center = calculateCenter(l.polygon)

        const content = document.createElement("div")
        content.className = "localidad-reporte-label"
        content.innerHTML = `
          <div style="display:flex; flex-direction:row;align-items:center;gap:6px;text-justify:middle">
            <div style="display:flex;flex-direction:column;gap:6px"> 
              <div><b>${l.nombre}</b></div>
            </div>
          </div>
        `

        const marker = new maps.marker.AdvancedMarkerElement({
          position: center,
          content,
          map
        })

      })

      console.log(tree)
      console.log(reportes)
    }
    refresh()
  },[maps, planillaId, localidadId])

  return <Box sx={{minHeight:"100%", display:"flex", flexDirection:"column"}}>
    {
      localidadesWithMissingPolygon?.length ? (
        <Alert variant="filled" severity="warning" icon={<Icon path={mdiMapMarkerAlert} size={1} />}>
          Los siguientes cuarteles no tienen un perímertro asignado:  {
            localidadesWithMissingPolygon.map(l=>l.nombre).join(", ")
          }. El mapa se muestra incompleto.
        </Alert>
      ) : null
    }
    <GMapsBaseBox
      sx={{height:"initial", flex:1}}
      ref={ref}
      defaultMessage={
        ! reportes.length
        ? "No hay reportes disponibles."
        : "Seleccione una planilla para ver los reportes en el mapa."
      }
      defaultIconPath={icons.planilla}
    />
  </Box>
}

const ReportesVistaMapa = GMapsWrapper(ReportesVistaMapaContent);

export default ReportesVistaMapa;