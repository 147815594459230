import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const DataTable = ({
  columns = [],
  getId = (row)=>row.id,
  getRowKey = getId,
  data = [],
  rowProps = ()=>({}),
  onRowEvent = (e,r) =>void 0
}) => {

  const handleRowEvent = (event, row)=>{
    onRowEvent && onRowEvent(event, row);
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {
              columns.map(col=>(
                <TableCell {...col.columnProps} key={col.key}>
                  <b>{col.header}</b>
                </TableCell> 
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map((row,index) => (
              <TableRow
                {...rowProps(row,index)}
                key={getRowKey(row)}
              >
                {
                  columns.map(({ Cell, columnProps={}, key })=>(
                    <TableCell {...columnProps} key={key}>
                      { Cell ? <Cell row={row} triggerRowEvent={handleRowEvent}/> : row[key]}
                    </TableCell>
                  ))
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );

}

export default DataTable;