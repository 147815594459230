import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  alpha
} from '@mui/material';
import { mdiDelete } from '@mdi/js';
import { Icon } from '@mdi/react';

const DeleteDialogButton = ({ title, question, onConfirm, open, onOpenChange, buttonText }) => {
  const [checked, setChecked] = useState(false)

  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timer,setTimer] = useState(5);
  const [intervalId, setIntervalId] = useState(null)

  const handleSetOpen = (value) => {
    onOpenChange(value);
  }

  useEffect(()=>{
    setChecked(false)
  },[open])
  useEffect(()=>{
    const cancelInterval = ()=>{
      clearInterval(intervalId);
      setIsTimerActive(false);
      setTimer(5);
    }
    if(checked){
      setIsTimerActive(true);
      const interval = setInterval(()=>{
        setTimer(prevTimer => {
          if(prevTimer === 1){
            cancelInterval()
            return 0;
          }
          return prevTimer - 1
        });
      }, 1000);
      setIntervalId(interval);
    }else{
      cancelInterval()
    }
  }, [checked]);
  
  const tcolor = theme=>theme.palette['error'].main 

  return (
    <>
      {
        buttonText
        ? <>
          <Button
            onClick={()=>handleSetOpen(true)}
            color="error"
            variant='text' 
            startIcon={<Icon path={mdiDelete} size={1}/>}
          >{buttonText}</Button>
        </>
        : 
        null
      }
      <Dialog
        open={open}
        onClose={()=>handleSetOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{backgroundColor: t => alpha(tcolor(t),0.5) }}
      >
        <Box display="flex" justifyContent="center" pb={0} pt={2} color={tcolor}>
          <Icon path={mdiDelete} size={4}/>
        </Box>
        <DialogTitle display="flex" justifyContent={"center"}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {question}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(e)=>setChecked(e.target.checked) }
                color='error'
              />
            }    
            label="Estoy seguro de mi decisión"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleSetOpen(false)}>
            No, cancelar
          </Button>
          <Button onClick={()=>onConfirm()} autoFocus disabled={!checked || isTimerActive} color="error">
            {
              isTimerActive ? `Espere 00:0${timer}` : "Sí, Eliminar"
            }
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDialogButton;