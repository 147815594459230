import { Autocomplete, Box, TextField } from "@mui/material";
import { useComunas } from "../contexts/ComunasProvider";

const ComunaAutocomplete = ({regionId,...props}) => {
  const { comunas, provincias } = useComunas();

  const filteredProvincias = provincias.filter(provincia=>provincia.region_id === regionId);
  const filteredComunas = comunas.filter(comuna => filteredProvincias.some(p => p.provincia_id === comuna.provincia_id))

  return <Autocomplete
    {...props}
    groupBy={(option) => option.provinciaNombre}
    options={filteredComunas}
    autoHighlight
    getOptionLabel={(option) => option.nombre}
    getOptionKey={option=>option.comuna_id}
    renderOption={(props, option) => (
      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        <img
          loading="lazy"
          width="16"
          src={option.escudo_url}
          alt=""
        />
        {option.nombre}
      </Box>
    )}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Comuna"
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // disable autocomplete and autofill
        }}
      />
    )}
  />
}
export default ComunaAutocomplete;