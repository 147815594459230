import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axios';
import parseJwt from '../../utils/parseJwt';
import { useUsuario } from '../../contexts/UsuarioProvider';

function useLogin(){
  
  const { setUsuario } = useUsuario();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const navigate = useNavigate();
  
  const login = (email, password) => {
    setIsLoading(true);
    setErrorMessage(null);
    axios.post("auth/login",{
      email,
      password
    }).then(response=>{
      const token = response?.data?.token;
      localStorage.setItem("token",token || "")

      const payload = parseJwt(token);
      const tipo = payload?.tipo;
      
      if(tipo === 'SYSADMIN'){
        navigate("/empresas")
      }else if( ['PROPIETARIO','INVITADO'].includes(tipo)){
        navigate("/empresas")
      } else {
        alert("TIPO DE USUARIO DESCONOCIDO")
      }
      
      setUsuario(response?.data?.Usuario);
    }).catch((error)=>{
      console.log(error)
      const {response,code} = error;
      if(code === "ERR_NETWORK")
        setErrorMessage("Sin conexión")
      else
        setErrorMessage(response?.data?.message)
      setIsLoading(false);
    })
  };
  
  return {
    isLoading,
    errorMessage,
    login
  }
}

export default useLogin;