import { mdiCodeLessThan, mdiCodeLessThanOrEqual, mdiApproximatelyEqualBox, mdiCheckboxBlank, mdiCheckboxBlankOff } from "@mdi/js"
import { Icon } from "@mdi/react"
import { IconButton } from "@mui/material"
import { useState } from "react"

export const LIMIT_DISABLED = 0
export const LIMIT_STRICT = 1
export const LIMIT_EQUALS = 2

const LimitButton = ({ value, onChange, disabled})=>{
  const icons = [ mdiCheckboxBlankOff, mdiCodeLessThan, mdiCodeLessThanOrEqual ]
  const [icon, setIcon] = useState(icons[value])
  
  const handleClick = ()=>{
    const newValue = (value + 1) % icons.length
    setIcon(icons[newValue])
    onChange(newValue)
  }

  return (
    <IconButton disabled={disabled} onClick={handleClick} color="primary">
      <Icon path={icon} size={1}/>
    </IconButton>
  )
}

export default LimitButton;