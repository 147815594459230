import { Box, Container } from '@mui/material';
const MainContainer = ({children})=>{
  
  return (
    <Box sx={{flex:1, overflow:"auto"}}>

      <Container sx={{pt:4, pb:4}}>
        { children }
      </Container>
    </Box>
  );
}

export default MainContainer;