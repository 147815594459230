import { createContext } from "react"

export const JefesContext = createContext({
  jefes: [],
  campos: [],
  jefeCampos: {
    value: null,
    seed: ({usuarios, campos}) => {},
    update: ({usuarioId, value}) => {}
  },
})