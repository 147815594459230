import { Autocomplete, TextField } from "@mui/material";
import { useEffect } from "react";

const EmpresaAutocomplete = ({empresas, label, onChange,...props})=>{
  const isDisabled = !empresas || empresas.length === 0;
  
  useEffect(()=>{
    if(empresas?.length === 1){
      onChange(null, empresas[0])
    }
  },[empresas])

  return <Autocomplete
    {...props}
    onChange={onChange}
    disabled={isDisabled}
    options={empresas}
    autoHighlight
    getOptionLabel={(option) => option.nombre}
    getOptionKey={option=>option.empresa_id}
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // disable autocomplete and autofill
        }}
      />
    )}
    isOptionEqualToValue={ (o,v)=> o.empresa_id === v?.empresa_id}
  />
}

export default EmpresaAutocomplete;