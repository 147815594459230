import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Icon } from '@mdi/react'
import { mdiSprout } from '@mdi/js';
import { Stack,Card, CardContent } from '@mui/material';
import useLogin from "./useLogin";

const version = "v0.2"
const buildId = "XXXXXXXX"

function Login() {
  const { login, errorMessage } = useLogin()

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    login(data.get('email'), data.get('password'))
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        height: '100vh',
        alignItems: 'center',
        pt:3,
        backgroundColor: theme=>theme.palette.grey[100]
      }}
    >
      <Card>
        <CardContent>
          <Stack component="form" onSubmit={handleSubmit} noValidate spacing={2} sx={{justifyContent:"center"}}>
            <Box sx={{display:"flex",justifyContent:"center"}}>
              <Avatar sx={{ width: 128, height: 128, bgcolor: "secondary.main" }}>
                <Icon path={mdiSprout} size={2}/>
              </Avatar>
            </Box>
            <Typography component="h1" variant="h3" sx={{mt:3,textAlign: "center"}}>
              Sistema de Gestión Agrícola
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {
              errorMessage 
              ? <Alert severity="error">{errorMessage}</Alert>
              : null
            }
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Iniciar sesión
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Login;