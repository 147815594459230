import { 
  axiosGetByIdFactory, 
  axiosGetFactory, 
  axiosPostFactory,
  axiosPutByIdFactory,
  axiosDeleteByIdFactory
} from "../axios";

export const getCampos = axiosGetFactory('/campo')
export const getCampoById = axiosGetByIdFactory('/campo')
export const postCampo = axiosPostFactory('/campo')
export const putCampo = axiosPutByIdFactory('/campo')
export const deleteCampoById = axiosDeleteByIdFactory('/campo')