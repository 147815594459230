import { createContext, useContext,useState, useEffect } from "react"
import { getEmpresas } from "../client/Empresa";
import { getCampos } from "../client/Campo";
import { getSectores } from "../client/Sector";
import { getCuarteles } from "../client/Cuartel";
import { useCrudReducer } from "../hooks/useCrudReducer";

export const NodeType = {
  CUARTEL: "cuartel",
  SECTOR: "sector",
  CAMPO: "campo",
  EMPRESA: "empresa"
}

export const LocalidadesContext = createContext({
  tree: [],
  selectedNode: null,
  setSelectedNodeId: ()=>{},
  getRoles: (selectedNode)=>void 0,
  empresas: null,
  campos: null,
  sectores: null,
  cuarteles: null,
})

export const useLocalidades = () => useContext(LocalidadesContext);

const toNode = (prefix, parentPrefix) => item => ({
  ...item,
  nodeId: `${prefix}-${item[prefix + "_id"]}`,
  parentId: parentPrefix ? `${parentPrefix}-${item[parentPrefix + "_id"]}` : null,
  type: prefix
})

const toTree = (...rawData)=>{
  const typeOrder = [null,NodeType.EMPRESA, NodeType.CAMPO, NodeType.SECTOR, NodeType.CUARTEL]
  const tree = []
  rawData.forEach(( data, index )=>{
    tree.push(...data.map(toNode(typeOrder[index + 1],typeOrder[index])))
  })
  return tree
}

export const LocalidadesProvider = ({ children }) => {
  const empresas = useCrudReducer("empresa_id");
  const campos = useCrudReducer("campo_id");
  const sectores = useCrudReducer("sector_id");
  const cuarteles = useCrudReducer("cuartel_id");
  const [ selectedNodeId, setSelectedNodeId ] = useState(null);
  
  const tree = toTree(
    empresas.state,
    campos.state,
    sectores.state,
    cuarteles.state
  );

  const getSelectedNode = () => selectedNodeId ? tree.find(node=>node.nodeId === selectedNodeId) : null
  const [ selectedNode, setSelectedNode ] = useState(getSelectedNode());

  const getRoles = ( currentNode )=>{
    if(!currentNode)
      return []

    if(currentNode.type === NodeType.EMPRESA)
      return currentNode.roles || []
    
    return getRoles(tree.find( parentCandidate => parentCandidate.nodeId === currentNode.parentId ))
  }

  useEffect(()=>{
    setSelectedNode(getSelectedNode())
  },[selectedNodeId, empresas.state, campos.state, sectores.state, cuarteles.state])

  const fetch = (fetcher,setter)=>{
    fetcher()
      .then(data=>setter(data))
      .catch(()=>{})
  }

  useEffect(()=>{
    fetch(getEmpresas, empresas.set)
    fetch(getCampos, campos.set)
    fetch(getSectores, sectores.set)
    fetch(getCuarteles, cuarteles.set)
  },[])

  return (
    <LocalidadesContext.Provider value={{ tree, selectedNode, setSelectedNodeId, empresas, campos, sectores, cuarteles,getRoles }}>
      { children }
    </LocalidadesContext.Provider>
  )
}