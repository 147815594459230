import { useState, useEffect } from "react";
import { putVariedad } from "../../client/Variedad";
import { Button, TextField, Card, Typography, Grid, CardContent, CardActions, List, ListItem, ListItemButton } from "@mui/material";
import { Icon }  from "@mdi/react";
import { mdiContentSave } from "@mdi/js";
import { useCultivosVariedades } from "./CultivosVariedadesContext";
import { usePopup } from "../../contexts/PopupProvider";

const PropiedadesPanel = ({ variedad }) => {
  const { variedades } = useCultivosVariedades();
  const [variedadId, setVariedadId] = useState(variedad?.variedadId);
  const [nombre, setNombre] = useState('');
  const [plantacion, setPlantacion] = useState(0);
  const [plantas, setPlantas] = useState('');
  const [entreH, setEntreH] = useState('');
  const [sobreH, setSobreH] = useState('');
  const [portainjerto, setPortainjerto] = useState('');
  const popup = usePopup();

  useEffect(() => {
    // Reset state values when "variedad" attribute changes
    setVariedadId(variedad?.variedad_id);
    setNombre(variedad?.nombre);
    setPlantacion(variedad?.año_plantacion || new Date().getFullYear());
    setPlantas(variedad?.n_plantas || 0);
    setEntreH(variedad?.dist_entre_hilera || 0);
    setSobreH(variedad?.dist_sobre_hilera || 0);
    setPortainjerto(variedad?.portainjerto || "");
  }, [variedad]);

  const handleSaveClick = () => {
    putVariedad(variedadId , {
      nombre,
      año_plantacion: plantacion,
      n_plantas: plantas,
      dist_entre_hilera: entreH,
      dist_sobre_hilera: sobreH,
      portainjerto
    }).then((props)=>{
      variedades.upsert(props)
      popup.success(`Variedad "${nombre}" guardada con éxito.`)
    }).catch(()=>{
      popup.error("No se pudo guardar la variedad")
    });
  };

  return (
    <>
      <Typography variant="h5" component="div" sx={{ display: "flex", justifyContent: "space-between" }}> 
        Propiedades
      </Typography>
      <Card>
        {
          !variedadId
          ? <>
            <List>
              <ListItem>
                <ListItemButton disabled>
                  Seleccione una variedad
                </ListItemButton>
              </ListItem>
            </List>
          </>
          : <>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Datos generales</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Variedad" value={nombre} onChange={(e) => setNombre(e.target.value)} />  
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Año plantación" value={plantacion} type="number" onChange={(e) => setPlantacion(e.target.value)} />  
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Número de plantas" value={plantas} type="number" onChange={(e) => setPlantas(e.target.value)} />  
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Marco de plantación</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Distancia entre hileras (metros)" type="number" value={entreH} onChange={(e) => setEntreH(e.target.value)} />  
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Distancia sobre hileras (metros)" type="number" value={sobreH} onChange={(e) => setSobreH(e.target.value)} />  
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Otros</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Portaingerto" value={portainjerto} onChange={(e) => setPortainjerto(e.target.value)} />  
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ justifyContent: "end" }}>
              <Button
                variant="contained"
                startIcon={<Icon path={mdiContentSave} size={1} />}
                onClick={handleSaveClick}
              >
                Guardar
              </Button>
            </CardActions>
          </>
        }
      </Card>
    </>
  );
};

export default PropiedadesPanel;
