import { Alert, Card, CardContent, Stack, TextField, Typography } from "@mui/material"
import NivelAutocomplete from "./NivelAutocomplete";
import CategoriaAutocomplete from "./CategoriaAutocomplete"
import SupervisorAutocomplete from "./SupervisorAutocomplete";
import { icons } from "../../utils/icons"
import { usePlanillaEditor } from "./PlanillaEditorContext"
import { useSupervisores } from "../../contexts/SupervisoresProvider";
import { useCategorias } from "./CategoriasContext";
import { mdiCalendarClock } from "@mdi/js";
import IconInputDecorator from "./IconInputDecorator"
import FrecuenciaSelector from "./FrecuenciaSelector";
import { useLocalidades } from "../../contexts/LocalidadesProvider";
import LocalidadCuartelesAutocomplete from "./LocalidadCuartelesAutocomplete"

const PlanillaHeader = ()=>{
  const { supervisores } = useSupervisores();
  const { categorias } = useCategorias();
  const { header, consolidada } = usePlanillaEditor();
  const { tree } = useLocalidades();
  

  return (<>
    <Typography variant="overline">
      Información básica
    </Typography>

    <Stack direction="row" spacing={2}>
      <IconInputDecorator path={ icons.planilla }/>

      <TextField
        label="Nombre"
        size="small"
        value={header.state.nombre ?? ""}
        onChange={e => header.setItem('nombre', e.target.value)}
        sx={{ flex:1 }}
      />

      <IconInputDecorator disabled={consolidada} path={ icons.categoria }/>
      
      <CategoriaAutocomplete
        categorias={categorias}
        value={header.state.categoria ?? null}
        onChange={v=>header.setItem('categoria',v)}
        disabled={consolidada}
        sx={{flex: 0.5}}
      />

      <IconInputDecorator path={ icons.supervisor }/>

      <SupervisorAutocomplete
        supervisores={supervisores.state}
        value={header.state.supervisor_ids ?? []}
        onChange={v=>header.setItem('supervisor_ids',v)}
      />
    </Stack>

    <Typography variant="overline">
      Tiempos
    </Typography>
    
    <Stack direction="row" spacing={2}>
      
      <IconInputDecorator disabled={consolidada} path={ mdiCalendarClock }/>
      
      <FrecuenciaSelector
        value={header.state.frecuencia}
        onChange={v=>header.setItem('frecuencia',v)} 
        disabled={consolidada}
      />    
    </Stack>

    <Typography variant="overline">
      Cuarteles Asociados
    </Typography>

    <Stack direction="row" spacing={2}>
      
      <IconInputDecorator disabled={consolidada} path={ icons[header.state.nivel?.toLowerCase()] ?? icons.localidades }/>
      
      <NivelAutocomplete
        value={header.state.nivel}
        onChange={v=>header.setItem('nivel',v)}
        disabled={consolidada}
      />

      <LocalidadCuartelesAutocomplete
        nivel={header.state.nivel}
        localidadId={header.state.localidad_id}
        onSelect={
          (localidad_id,localidad_ids)=>{
            header.setItem('localidad_id',localidad_id);
            header.setItem('localidad_ids',localidad_ids);
          }
        }
        disabled={consolidada}
      />
    </Stack>
    
    {
      header.state.localidad_ids?.length > 0 
      ? (
        <Alert severity="info" >
          Cuarteles seleccionados: {
            header.state.localidad_ids?.map(id=>tree.find(c=>c.localidad_id === id)?.nombre?? '').join(', ')
          }
        </Alert>
      )
      : header.state.localidad_id !== null 
      ? (
        <Alert severity="warning" >
          Sin cuarteles seleccionados
        </Alert>
      )
      : null
    }
  </>
  );
}

export default PlanillaHeader;