import { Avatar, Box, Card } from "@mui/material";
import TreeViewDrawer from "./TreeViewDrawer";
import MainContainer from "../../components/MainContainer";
import BaseBox from "../../components/BaseBox";
import NodeIcon from "../../components/NodeIcon";
import { Stack, Toolbar, Typography, List, ListItem, ListItemButton } from "@mui/material";
import NavDrawer from "../../components/Drawer/NavDrawer";
import NodeContent from "./NodeContent"
import { useLocalidades } from "../../contexts/LocalidadesProvider";

const Localidades = () => {
  const { selectedNode } = useLocalidades();

  return (
    <BaseBox>
      <NavDrawer/>
      <TreeViewDrawer/>
      <MainContainer>
      {
        selectedNode ? (
          <NodeContent/>
        ) : (
          <List>
            <ListItem>
              <ListItemButton disabled>
                <i>Seleccione un nodo para ver detalles</i>
              </ListItemButton>
            </ListItem>
          </List>
        )
      }
      </MainContainer>
    </BaseBox>
  );
}
export default Localidades;