import { TableContainer,Table,TableHead,TableRow,TableCell,TableBody, Card, ListItemButton, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import { useContext } from "react";
import { postJefeCampos } from "../../client/Jefe";
import { JefesContext } from "./JefesContext";
import { usePopup } from "../../contexts/PopupProvider";

const JefesTable = ({ empresaId }) => {
  const { jefes, campos, jefeCampos } = useContext(JefesContext);
  const popup = usePopup()
  
  const handleUpdateCampos = (jefe) => (_,campos)=>{
    postJefeCampos({
      usuario_id: jefe.usuario_id,
      campo_ids: campos.map(campo=>campo.campo_id),
      empresa_id: empresaId
    })
      .then(()=>{
        jefeCampos.update({ usuarioId: jefe.usuario_id, campos })
      })
      .catch(()=>{
        popup.error(`No se pudo actualizar Campo para ${jefe.nombres} ${jefe.apellidos}`)
      })
  }
  
  return (
    <TableContainer component={Card}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">  
        <TableHead>
          <TableRow>
            <TableCell sx={{ maxWidth: 160, width:160 }}><b>Jefe</b></TableCell>
            <TableCell><b>Campos</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { 
            ! empresaId
            ? (
              <TableRow>
                <TableCell colSpan="2" sx={{m:0,p:0}}> 
                  <ListItem disablePadding>
                    <ListItemButton disabled>
                      Seleccione una empresa
                    </ListItemButton>
                  </ListItem>
                </TableCell>
              </TableRow>
            ) : (
              jefes.state.map((row) => (
              <TableRow key={row.usuario_id}>
                <TableCell component="td">
                  <div>{row.nombres + " " + row.apellidos}</div>
                </TableCell>

                <TableCell component="td">
                  <Autocomplete
                    multiple
                    options={campos.state.filter(campo=>campo.empresa_id === empresaId)}
                    getOptionLabel={(option) => option.nombre}
                    value={jefeCampos.value[row.usuario_id]}
                    size="small"
                    onChange={handleUpdateCampos(row)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                      />
                    )}
                  />

                </TableCell>
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default JefesTable;