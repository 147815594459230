import { useEffect, useState } from "react";
import { getCategorias } from "../../client/Categoria";
import { usePlanillas } from "./PlanillasContext";

const useCategorias = ()=>{
  const [ categorias, setCategorias ] = useState([])
  const { empresaSelected } = usePlanillas();

  useEffect(()=>{
    if(empresaSelected) {
      getCategorias({empresa_id: empresaSelected.empresa_id})
        .then (categorias=>{
          setCategorias(categorias)
        })
        .catch(error=>{
          console.log(error)
        })
    }
  },[ empresaSelected ]);

  return { categorias }
}

export { useCategorias }