import { memo, useEffect, useState } from "react"
import BaseBox from "../../components/BaseBox"
import NavDrawer from "../../components/Drawer/NavDrawer"
import { getPlanillasEstadoReportes } from "../../client/Planilla"
import { getReportesHistorico } from "../../client/Reporte"
import EmpresaSearchPanel from "../../components/EmpresaSearchPanel"
import { Alert, Box, Button, ButtonGroup, Card, Paper, Stack } from "@mui/material"
import { Icon } from "@mdi/react"
import DataTable from "../../components/DataTable"
import { generateHistoricoColumns } from "./HistoricoColumns"
import { usePopup } from "../../contexts/PopupProvider"
import { mdiHistory, mdiMapMarker, mdiTable } from "@mdi/js"
import { useNavigate } from "react-router-dom"
import ReportesVistaTabla from "./ReportesVistaTabla"
import ReportesVistaMapa from "./ReportesVistaMapa"
import { ReportesContext } from "./ReportesContext"
import ReportesPlanillaSelector from "./ReportesPlanillaSelector"
import { PlanillasProvider } from "../Planillas/PlanillasContext"


const Reportes = ()=>{

  const [ empresaId, setEmpresaId ] = useState(null)
  const [ reportes, setReportes ] = useState([]);

  const [ planillaId, setPlanillaId ] = useState(null);
  const [ localidadId, setLocalidadId ] = useState(null);
  const [ historico, setHistorico ] = useState([])

  const historicoColumns = generateHistoricoColumns(reportes.find(p=>p?.planilla_id === planillaId)?.schema)

  const popup = usePopup();

  const navigate = useNavigate()
  
  const filteredReportes = reportes.filter(
    p => p.empresa_id === empresaId
  );

  useEffect(()=>{
    const fetch = async ()=>{
      getPlanillasEstadoReportes()
        .then((data)=>{
          setReportes(data)
        })
        .catch(()=>{});
    }
    fetch();
  },[])

  const handleRowEvent = (event,row)=>{
    if(event === "VER_HISTORICO"){
      getReportesHistorico({
        planilla_id: row.planilla_id,
        localidad_id: row.localidad_id
      })
      .then((data)=>{
        setPlanillaId(row.planilla_id);
        setLocalidadId(row.localidad_id);
        setHistorico(data)
      })
      .catch(()=>{
        popup.error("No se ha podido cargar el histórico")
      });
    }
    if(event === "CERRAR_HISTORICO"){
      setPlanillaId(null)
      setLocalidadId(null)
      setHistorico([])
    }
    if(event === "NUEVO_REPORTE"){
      const params = new URLSearchParams({
        planilla_id: row.planilla_id,
        localidad_id: row.localidad_id
      })
      navigate(`/generar-reporte?${params}`)
    }
  }

  const TABLA = 0
  const MAPA = 1
  const [vista, setVista] = useState(TABLA)
  const ButtonOption = memo(({value,children,iconPath}) =>{
    return <Button variant={vista===value ?'contained': 'outlined'} onClick={()=>{setVista(value);setLocalidadId(null)}} startIcon={<Icon path={iconPath} size={1}/>}>{children}</Button>
  })

  return (
    <BaseBox>
      <NavDrawer/>
      <PlanillasProvider>
        <ReportesContext.Provider value={{reportes:filteredReportes, planillaId, setPlanillaId, localidadId, setLocalidadId}}>
          <Box sx={{overflow:"auto",p:1,flex:1}}>
            <Box sx={{gap:1,display:"flex", flexDirection:"column", height:"100%"}}>
              <EmpresaSearchPanel onSelect={setEmpresaId}/>
                <Stack direction="row" spacing={2}>
                  <Paper sx={{width:"fit-content",height:"fit-content",display:"flex"}}>
                    <ButtonGroup size="large">
                      <ButtonOption value={TABLA} iconPath={mdiTable}>Vista tabla</ButtonOption>
                      <ButtonOption value={MAPA} iconPath={mdiMapMarker}>Vista Mapa</ButtonOption>
                    </ButtonGroup>
                  </Paper>
                  <ReportesPlanillaSelector/>
                </Stack>

            
              
                { vista === TABLA && <Card><ReportesVistaTabla handleRowEvent={handleRowEvent}/></Card> }
                { vista === MAPA && <Card sx={{flex:1}}><ReportesVistaMapa/></Card> }

              {
                vista === TABLA &&
                <Card>
                  {
                    localidadId && planillaId
                    ?
                    <DataTable
                    columns={historicoColumns}
                    getId={row=>row.reporte_id}
                    data={historico}
                    />
                    : 
                    <Alert severity="info" icon={<Icon path={mdiHistory} size={1}/>}>
                      Los datos históricos se verán aquí
                    </Alert>
                  }
                </Card>
              }
            </Box>
          </Box>
        </ReportesContext.Provider>
      </PlanillasProvider>
    </BaseBox>
  )
}

export default Reportes