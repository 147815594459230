import { FormControl, Stack, TextField } from "@mui/material";
import DataTypeRange from "./DataTypeRange";
import { useState } from "react";

const DatatTypeDecimal = ({ value, onChange, disabled,...props }) => {

  const { 
    precision = 0,
    ...range
  } = value;


  const handlePrecisionChange  = (e) => {
    const precision = Number(e.target.value) ?? 0
    if(!onChange) return

    if(precision>0){
      onChange({ ...value, precision })
    }
    else{
      onChange({ ...value, precision: 0})
    }
  }

  const handleRangeChange = range => {
    if(!onChange) return
    const oldValue = {...value};
    
    delete oldValue.gt;
    delete oldValue.gte;
    delete oldValue.lt;
    delete oldValue.lte;

    onChange({ ...oldValue, ...range })
  }


  return <Stack spacing={2}>
    <TextField
      {...props}
      type="number"
      label="Precisión"
      value={ precision }
      onChange={handlePrecisionChange}
      disabled={disabled}
    />
    <DataTypeRange
      value={range}
      onChange={handleRangeChange}
      disabled={disabled}
    />
  </Stack>
}

export default DatatTypeDecimal;