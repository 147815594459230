import { Box } from "@mui/material"


const BaseBox = ({children})=>{
  
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      height: "100dvh",
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[900]
    }}
      component="main"
    >
      {children}
    </Box>
  )
}

export default BaseBox