import { createContext, useContext, useState, useEffect } from "react";
import { getUsuario } from "../client/Usuario";

const UsuarioContext = createContext({
  /** @type {{ usuario_id: number, tipo: 'PROPIETARIO' | 'INVITADO' | 'SYSADMIN', nombres:string, apellidos:string, rut:string}} */
  usuario: {},
  setUsuario: ()=> null
})

export const useUsuario = () => useContext(UsuarioContext);

export const UsuarioProvider = ({ children })=>{
  const [ usuario, setUsuario ] = useState({})

  useEffect(()=>{
    getUsuario()
    .then(usuario=>{
      setUsuario(usuario)
    })
    .catch(()=>{});
  },[])

  return (
    <UsuarioContext.Provider value={{ usuario, setUsuario }}>
      { children }
    </UsuarioContext.Provider>
  )
}