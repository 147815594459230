import { Autocomplete, Grid, Box, Card, CardActions, CardContent, Stack, TextField, Button, ListItem, ListItemIcon, ListItemText, ListItemButton, Alert, FormControl, FormControlLabel, Typography } from "@mui/material";
import MainContainer from "../../components/MainContainer"
import { usePlanillas } from "../Planillas/PlanillasContext";
import { useState, useEffect } from "react";
import { useStorageReducer } from "../../hooks/useStorageReducer";
import { Icon } from "@mdi/react";
import { icons } from "../../utils/icons";
import { 
  mdiContentSave,
  mdiMapMarkerCheck,
  mdiMapMarkerOff,
  mdiTableOff
} from "@mdi/js";
import FieldFactory from "./FieldFactory";
import { useGPS } from "../../contexts/GPSProvider";
import { useLocalidades } from "../../contexts/LocalidadesProvider";
import { getReporteByOnPlanillaDates, postReporte } from "../../client/Reporte";
import { findNearestPolygonIndex } from "../../utils/SphereGeometry"
import { usePopup } from "../../contexts/PopupProvider";
import { useUsuario } from "../../contexts/UsuarioProvider";

const CardAlert = ({iconPath, children,...props})=>(
  <Alert
    icon={iconPath ? <Icon path={iconPath} size={1}/> : undefined}
    severity="error"
    elevation={0}
    {...props}
  >
    {children}
  </Alert>
)

const Helper = ({iconPath,children, color="primary"})=>{
  return (
    <Box sx={{display:"flex", alignContent:"center", gap:1, color: theme=>theme.palette[color].main}}>
      <Icon path={iconPath} size={2/3}/>
      {children}
    </Box>
  );
}

const GenerarReporteContent = ()=>{
  const { usuario } = useUsuario()
  const { planillas } = usePlanillas();
  const [ planillaSelected, setPlanillaSelected ] = useState(null);
  const { campos, sectores, cuarteles, tree } = useLocalidades();
  const [ localidadSelected, setLocalidadSelected ] = useState(null);
  const [ localidadSelectedBy, setLocalidadSelectedBy ] = useState(false);
  const [ reporteExistente, setReporteExistente ] = useState(null);

  const NONE = 0;
  const CLOSENESS = 1;
  const UNIQUENESS = 2;
  const URL_PARAMS = 3;
  const { position: gpsPosition } = useGPS();

  const form = useStorageReducer()

  const popup = usePopup()

  const canEdit = !reporteExistente || reporteExistente?.supervisor_id === usuario?.usuario_id

  const urlParams = new URLSearchParams(window.location.search);
  useEffect(()=>{
    const pid = urlParams.get('planilla_id')
    const lid = urlParams.get('localidad_id')
    if(pid && lid && planillas.state.length && tree.length){
      console.log("SELECTED BY URL")
      setPlanillaSelected(planillas.state.find(p=>p.planilla_id === Number(pid)))
      setLocalidadSelected(tree.find(t=>t.localidad_id === Number(lid)))
      setLocalidadSelectedBy(URL_PARAMS)
    }
  },[urlParams.toString(), planillas.state, tree])

  const calculateOptions = () => (cuarteles.state || []).filter(c=>planillaSelected?.localidad_ids?.includes(c.localidad_id))

  const cuartelOptions = calculateOptions();

  useEffect(()=>{
    if(!planillaSelected) return;
    if(localidadSelectedBy === URL_PARAMS) return;

    if(cuartelOptions.length === 1){
      setLocalidadSelected(cuartelOptions[0])
      setLocalidadSelectedBy(UNIQUENESS)
      return;
    }
    
    const polygons = cuartelOptions.map(option=>option.polygon)

    const nearestIndex = findNearestPolygonIndex(polygons, gpsPosition)
    
    if(nearestIndex === -1){
      setLocalidadSelected(null)
      setLocalidadSelectedBy(NONE)
    }else{
      setLocalidadSelected(cuartelOptions[nearestIndex])
      setLocalidadSelectedBy(CLOSENESS)
    }

  },[planillaSelected, gpsPosition, campos.state, sectores.state, cuarteles.state ])

  const planillaSelectedLocalidad = !planillaSelected ? null : tree.find( t => t.localidad_id === planillaSelected.localidad_id )

  useEffect(()=>{
    if(!localidadSelected){
      setReporteExistente(null)
      return;
    }
    
    const nullValues = {}
    const schema = planillaSelected.schema
    for(let p = 0; p <schema.length; p++) {
      nullValues[schema[p].key]=null
    }

    getReporteByOnPlanillaDates({
      planilla_id: planillaSelected?.planilla_id,
      localidad_id: localidadSelected?.localidad_id
    })
    .then((reporte)=>{
      setReporteExistente(true)
      form.set({
        ...nullValues,
        ...reporte.data
      })
    })
    .catch(()=>{
      setReporteExistente(null)
      form.set({
       ...nullValues
      })
    })
  }, [ localidadSelected ])


  const handleSave = () => {
    postReporte({
      planilla_id: planillaSelected?.planilla_id,
      localidad_id: localidadSelected?.localidad_id,
      data: form.state
    })
    .then(data=>{
      popup.success("Reporte publicado con éxito")
    })
    .catch(({response})=>{
      const type = response.data.type
      popup.error(
        type === "REPORTE_ERROR"
        ? response.data.message
        : "No se pudo guardar el reporte"
      )
    })
  }

  return (
    <MainContainer>
      <Stack spacing={2}>
        <Card>
          <CardContent>
            <Autocomplete
              options={planillas.state}
              getOptionLabel={(option) => option.nombre}
              renderInput={(params) => (
                <TextField {...params} label="Planilla" variant="outlined" />
              )}
              value={planillaSelected}
              onChange={(_,v)=>setPlanillaSelected(v)}
            />
          </CardContent>
        </Card>                      
        { 
          // SIN PLANTILLA

          !planillaSelected ? (
            <CardAlert
              iconPath={icons.planilla}
              severity="info"
              elevation={1}
            >
              Seleccione una planilla
            </CardAlert>
          ) 

          // SIN LOCALIDADES

          : !planillaSelected?.localidad_ids?.length ? (
            <CardAlert iconPath={mdiMapMarkerOff}>
              Esta planilla no tiene localidades por trabajar. Notifique este error a su JEFE DE CAMPO.
            </CardAlert>
          ) 
          
          // SIN SCHEMA

          : !planillaSelected?.schema?.length ? (
            <CardAlert iconPath={mdiTableOff}>
              Esta planilla no tiene parámetros. Notifique este error a su JEFE DE CAMPO.
            </CardAlert>
          ) 
          
          // CON SCHEMA Y LOCALIDADES

          : planillaSelected?.schema.length && planillaSelected.localidad_ids ? <>
            <Card>
              <CardContent>
                <Autocomplete
                  options={ cuartelOptions }
                  getOptionLabel={(option) => option.nombre}
                  disabled={ cuartelOptions.length === 1 }
                  renderInput={props=>{
                    return <TextField
                      {...props}
                      value={props.value ?? null}
                      label={ "Selecccione cuartel trabajado" }
                      variant="outlined"
                      helperText={
                        localidadSelectedBy === CLOSENESS && (
                          <Helper iconPath={mdiMapMarkerCheck}>
                            Autoseleccionado por cercanía
                          </Helper>
                        ) 
                      }
                    />
                  }}
                  value={ localidadSelected }
                  onChange={ (_,v)=>{
                    setLocalidadSelected(v)
                    setLocalidadSelectedBy(NONE)
                  }}
                />
              </CardContent>
              {
                // LA PLANILLA TRABAJA SOBRE LOS CUARTLELES DE UNA LOCALIDAD (Campo/Sector/Cuartel)
                planillaSelected.nivel === "CUARTEL" && localidadSelectedBy === UNIQUENESS

                ? ( 
                  <CardAlert severity="info" iconPath={icons.cuartel}>
                    Esta planilla trabaja sobre un cuartel único.
                  </CardAlert>
                ) 
                
                : planillaSelected.nivel ? (
                  <CardAlert severity="info" iconPath={icons[planillaSelected.nivel.toLowerCase()]}>
                    Esta planilla trabaja sobre los cuarteles del <b>{planillaSelected.nivel}</b> "{planillaSelectedLocalidad.nombre}".
                  </CardAlert>
                )
                : null

              }
            </Card>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  {
                    planillaSelected.schema?.map(item=>
                      <>
                        <Grid item xs={4} sx={{display: "flex", alignSelf:"center"}}>
                          <Typography variant="body-1">
                            {item.nombre}
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{flex:1}}>
                          <FieldFactory
                            {...item}
                            disabled={!canEdit}
                            fullWidth
                            key={item.key}
                            value={form.state[item.key]}
                            onChange={(v)=>typeof v === "undefined" ? form.removeItem(item.key) : form.setItem(item.key,v)}
                            />
                        </Grid>
                      </>)
                    }
                </Grid>
              </CardContent>
              {
                !canEdit
                ? (
                  <CardAlert severity="error">
                    No puedes editar este reporte.
                  </CardAlert>
                ) : reporteExistente ? (
                  <CardAlert severity="warning">
                    Se modificará un reporte existente.
                  </CardAlert>
                ) : null 
              }
            </Card>
          </>
          : null
        }
        {
          planillaSelected && (
            <CardActions sx={{justifyContent:"end"}} disabled={!planillaSelected}>
              <Button
                startIcon={<Icon path={mdiContentSave} size={1}/>}
                variant="contained"
                onClick={handleSave}
              >
                Guardar
              </Button>
            </CardActions>
          )
        }
      </Stack>
    </MainContainer>
  );
}

export default GenerarReporteContent;