import { Card } from "@mui/material"
import { useLocalidades, NodeType } from "../../contexts/LocalidadesProvider"
import DataEmpresa from "./DataEmpresa";
import DataCampo from "./DataCampo";
import DataSector from "./DataSector";
import DataCuartel from "./DataCuartel";
import FormEmpresa from "./FormEmpresa";
import FormCampo from "./FormCampo";
import FormSector from "./FormSector";
import FormCuartel from "./FormCuartel";

const NodeContent = ()=>{
  const { selectedNode, getRoles } = useLocalidades();

  const isAdmin = getRoles(selectedNode)?.includes("ADMIN")

  const content = {
    [ false ] : {
      [NodeType.EMPRESA]: DataEmpresa,
      [NodeType.CAMPO]:   DataCampo,
      [NodeType.SECTOR]:  DataSector,
      [NodeType.CUARTEL]: DataCuartel,
    },
    [ true ] :{
      [NodeType.EMPRESA]: FormEmpresa,
      [NodeType.CAMPO]:   FormCampo,
      [NodeType.SECTOR]:  FormSector,
      [NodeType.CUARTEL]: FormCuartel,
    }
  }

  const Content = content[isAdmin][selectedNode.type]

  return <Content key={selectedNode.nodeId} data={selectedNode}/>
}

export default NodeContent