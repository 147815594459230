import { Button, Box} from "@mui/material"
import { Icon } from "@mdi/react"
import { useNavigate } from "react-router-dom"
import { mdiLogout } from "@mdi/js"
import { useDrawer } from "../../contexts/Drawer"

const LogoutDrawerItem = () => {
  const { open } = useDrawer()


  const navigate = useNavigate()
  const handleLogOutClick = () => {
    localStorage.token = ""
    navigate('/')
  }

  return (
    <Box sx={{px:1}}>
      <Button
        onClick={handleLogOutClick}
        sx={{
          background: "white",
          minWidth: 0,
          overflow:"hidden",
          justifyContent: "start", 
          borderRadius: open ? undefined: 24,
          transition: "border-radius 225ms ease-in-out",
          transitionDelay: 0,
          height: 48,
          "&:hover": {background: "white"}
        }}
        color="warning"
        fullWidth
      >
        <Box sx={{
            width:32,
            display:"inline-flex",
            px:0.5
          }}
        >
          <Icon path={mdiLogout} size={1}/>
        </Box>
        <Box sx={{
            pl:open ? 1 : 0,
            opacity:open ? 1 : 0,
            display:"inline-block",
            flex: open ? 1 : 0,
            overflow:"hidden",
            transition:"all 225ms ease-in-out"
          }}
        >
          Salir
        </Box>
      </Button>
    </Box>
  )
}
export default LogoutDrawerItem