
import { Grid, Typography } from "@mui/material"

const SimpleDataRow = ({label,value})=>{
  return <>
    <Grid item xs={4} sx={{display:"flex",alignContent:"center", textAlign:"end", justifyContent:"end"}}>
      <Typography variant="caption">{String(label).toUpperCase()}</Typography>
    </Grid>

    <Grid item xs={8} sx={{display:"flex",alignContent:"center"}}>
      <Typography variant="body1">{value}</Typography>
    </Grid>
  </>
}

export default SimpleDataRow;