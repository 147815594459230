import { createContext, useContext, useEffect } from "react";
import { useCrudReducer } from "../../hooks/useCrudReducer";
import { getCultivos } from "../../client/Cultivo";
import { getVariedades } from "../../client/Variedad";

const CultivosVariedadesContext = createContext({
  cultivos: null,
  variedades: null
})

export const useCultivosVariedades = ()=>useContext(CultivosVariedadesContext);

export const CultivosVariedadesProvider = ({ children, sectorId }) => {
  const cultivos = useCrudReducer('cultivo_id');
  const variedades = useCrudReducer('variedad_id');

  const fetch = (fetcher,setter)=>{
    if(!sectorId) return;
    fetcher({sector_id: sectorId})
      .then(data=>setter(data))
      .catch(()=>{})
  }

  useEffect(()=>{
    fetch(getCultivos, cultivos.set)
    fetch(getVariedades, variedades.set)
  },[sectorId])

  return (
    <CultivosVariedadesContext.Provider value={{ cultivos, variedades }}>
      { children }
    </CultivosVariedadesContext.Provider>
  );
}

