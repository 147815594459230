import { Tooltip, Box,Typography,IconButton } from "@mui/material"
import { Icon } from "@mdi/react"
import { mdiCheck, mdiClockOutline, mdiHammer, mdiHistory } from "@mdi/js"
import { useLocalidades } from "../../contexts/LocalidadesProvider";
import { formatDate } from "../../utils/date";

export const REPORTES_COLUMNS = [
  {
    key: "estado",
    Cell: ({row})=>(
      <Tooltip title={row.estado} placement="top">
        {
          row.estado === 'Listo'
          ? (
            <Box sx={{display:"inline-flex",color:theme=>theme.palette.primary.main}}>
              <Icon path={mdiCheck} size={2/3}/>
            </Box>
          ) : (
            <Box sx={{display:"inline-flex",color:theme=>theme.palette.warning.main}}>
              <Icon path={mdiClockOutline} size={2/3}/>
            </Box>
          )
        }
      </Tooltip>
    )
  },
  {
    key: "nombre",
    header: "Planilla",
    Cell: ({row})=><>
      {row.nombre}&nbsp;
      <Typography component="code" variant="caption" sx={{opacity:0.8}}>
        #{row.planilla_id}
      </Typography>
    </>
  },
  {
    key: "cuartel_id",
    header: "Cuartel",
    columnProps: { sx: {textAlign:"center"} },
    Cell: ({row})=> {
      const { tree } = useLocalidades();
      const findNode = (localidad_id) => tree.find(node=>node.localidad_id === localidad_id)
      return findNode(row.localidad_id)?.nombre
    }
  },
  {
    key: "frecuencia",
    header: "Frecuencia",
    columnProps: { sx: {textAlign:"center", width:160 } },
  },
  { 
    key: "createdAt",
    header: "Fec. últ. reporte",
    columnProps: { sx:{textAlign:"center", width:160}},
    Cell: ({row})=>formatDate(row.createdAt) ?? "-"
  },
  { 
    key: "siguiente_revision",
    header: "Fec. sig. reporte",
    columnProps: {sx:{textAlign:"center", width:160}},
    Cell: ({row})=>formatDate(row.siguiente_revision) ?? "-"
  },
  { 
    key: "supervisor",
    header: "Supervisor",
    columnProps: {sx:{textAlign:"center"}}
  },
]