import { useState } from "react";
import {  Stack,TextField, Button, Box } from "@mui/material";
import RegionAutocomplete from "../../components/RegionAutocomplete"
import ComunaAutocomplete from "../../components/ComunaAutocomplete"
import { mdiContentSave } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useLocalidades } from "../../contexts/LocalidadesProvider";
import { deleteEmpresaById, putEmpresa } from "../../client/Empresa";
import { useComunas } from "../../contexts/ComunasProvider";
import LocalidadCard from "./LocalidadCard";
import { usePopup } from "../../contexts/PopupProvider";
import DownloadXLS from "./DonwloadXLS";
import DeleteDialogButton from "../../components/DeleteDialogButton";
import { useUsuario } from "../../contexts/UsuarioProvider";

const FormEmpresa = ({ data }) => {
  const { usuario } = useUsuario();

  const { findComunaById, findRegionByComunaId } = useComunas();
  const { selectedNode, empresas } = useLocalidades();

  const [nombre, setNombre] = useState(data.nombre)
  const [rut, setRut] = useState(data.rut)
  const [region, setRegion] = useState(findRegionByComunaId(data.comuna_id))
  const [comuna, setComuna] = useState(findComunaById(data.comuna_id))
  const [localidad, setLocalidad] = useState(data.localidad)
  const [direccion, setDireccion] = useState(data.direccion)
  const [representanteLegal, setRepresentanteLegal] = useState(data.representante_legal)
  const [email, setEmail] = useState(data.propietario_email)
  const popup = usePopup()

  const handleSaveClick = () => {
    console.log(comuna)
    putEmpresa( selectedNode.empresa_id, {
      nombre,
      rut,
      comuna_id: comuna?.comuna_id,
      localidad,
      direccion,
      representante_legal: representanteLegal,
      propietario_email: usuario.tipo === 'SYSADMIN' ? email : undefined
    })
      .then(data=>{
        empresas.upsert(data);
        popup.success(`La empresa "${nombre}" se ha guardado correctamente.`);
      })
      .catch(err=>{
        popup.error("No se pudo guardar la empresa")
      })
  }

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const handleDelete = () => {
    deleteEmpresaById(selectedNode.empresa_id)
    .then(data=>{
      empresas.drop({empresa_id: selectedNode.empresa_id})
      popup.success(`La empresa "${nombre}" se ha eliminado correctamente.`);
      setDeleteDialogOpen(false)
    })
    .catch(err=>{
      popup.error("No se pudo eliminar la empresa")
      setDeleteDialogOpen(false)
    })
  }

  return <>
    <LocalidadCard node={{...data, type:"empresa"}}>
      <Stack spacing={2}>
        <TextField fullWidth label="Nombre" value={nombre} onChange={(e)=>setNombre(e.target.value)}/>
        <TextField fullWidth label="Rut" value={rut} onChange={(e)=>setRut(e.target.value)}/>
        <RegionAutocomplete value={region} onChange={(e,v)=>{setComuna(null);setRegion(v)}}/>
        <ComunaAutocomplete regionId={region?.region_id} value={comuna} onChange={(n,v)=>setComuna(v)}  />
        <TextField fullWidth label="Localidad" value={localidad} onChange={(e)=>setLocalidad(e.target.value)}/>
        <TextField fullWidth label="Direccion" value={direccion} onChange={(e)=>setDireccion(e.target.value)}/>
        <TextField fullWidth label="Representante Legal" value={representanteLegal} onChange={(e)=>setRepresentanteLegal(e.target.value)}/>
        {
          usuario.tipo === "SYSADMIN" && <>
            <hr/>
            <TextField fullWidth label="Email del propietario" value={email} onChange={(e)=>setEmail(e.target.value)}/>
          </>
        }
      </Stack>
    </LocalidadCard>
    <Stack direction="row" spacing={2} justifyContent="end" sx={{mt:2}}>
      <Button onClick={handleSaveClick} startIcon={<Icon path={mdiContentSave} size={1}/>} variant="contained">
        Guardar
      </Button>
      <Box flexGrow={1}/>
      <DownloadXLS/>
      {
        usuario.tipo === 'SYSADMIN' &&
        <DeleteDialogButton
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          onConfirm={handleDelete}
          title="Eliminar Empresa"
          buttonText="Eliminar Empresa"
          question={<>
            ¿Estás seguro que deseas eliminar la empresa <b>"{nombre}"</b>? 
            Una vez eliminada no se podrán recuperar sus campos, sectores ni cuarteles.
            Así como también los datos de los cultivos asociados a estos, planillas creadas y usuarios.
          </>}
        />
      }
    </Stack>
  </>
}

export default FormEmpresa;