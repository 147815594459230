import { useReducer } from "react"

const storageReducer = (state, {action, payload})=>{
  switch(action){
    case 'SET':
      return payload
    case 'SET_ITEM':
      return {...state, [payload.item]: payload.value}
    case 'REMOVE_ITEM':
      const newState = {...state}
      delete newState[payload.item]
      return newState
    default:
      return state
  }
}

export const useStorageReducer = (defaultValue = {})=>{
  const [ state, dispatch ] = useReducer(storageReducer, defaultValue)

  return {
    state,
    set: (obj)=>dispatch({ action: 'SET', payload: obj}),
    setItem: (item, value)=>dispatch({ action: 'SET_ITEM', payload: {item,value}}),
    removeItem: (item) => dispatch({ action: 'REMOVE_ITEM', payload: {item}})
  }
}