import BaseBox from "../../components/BaseBox";
import NavDrawer from "../../components/Drawer/NavDrawer";
import { PlanillasProvider } from "../Planillas/PlanillasContext";
import GenerarReporteContent from "./GenerarReporteContent";

const GenerarReporte = ()=>{

  return (
    <BaseBox>
      <NavDrawer/>
      <PlanillasProvider>
        <GenerarReporteContent/>
      </PlanillasProvider>
    </BaseBox>
  )

}

export default GenerarReporte;