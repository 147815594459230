import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Button, alpha } from "@mui/material";
import { createContext, useState, useContext } from "react";
import { Icon } from "@mdi/react";
import { mdiAlert, mdiAlertOctagon, mdiCheck, mdiInformation } from "@mdi/js";

const PopupContext = createContext({
  info: ( options ) => void 0,
  error: ( options ) => void 0,
  success: ( options ) => void 0,
})

export const usePopup = () => useContext(PopupContext)

const Popup = ({title, message, iconPath, onClose, open, color})=>{
  const tcolor = theme=>theme.palette[color].main 
  return <Dialog open={open} onClose={onClose} sx={{backgroundColor: t => alpha(tcolor(t),0.5), "& .MuiPaper-root": {width:360,maxWidth: "calc(100vw - 32px)" } }}>
    {
      title ? (
        <DialogTitle sx={{alignItems:"center", display:"flex", gap:1}}>
          <Box sx={{display:"inline-flex", color: tcolor}}>
            <Icon path={iconPath} size={1}/>
          </Box>
        </DialogTitle>
      ) : (
        <Box display="flex" justifyContent="center" pb={0} pt={2} color={tcolor}>
          <Icon path={iconPath} size={4}/>
        </Box>
      )
    }
    <DialogContent>
      { message }
    </DialogContent>
    <DialogActions>
      <Button variant="contained" disableElevation onClick={onClose} color={color}>
        OK
      </Button>
    </DialogActions>
  </Dialog>
}

export const PopupProvider = ({children}) => {
  const [ popups, setPopups ] = useState([]);

  const handlePopupClose = (_, index) => () => {
    setPopups([
      ...popups.slice(0,index),
      ...popups.slice(index+1)
    ])
  }

  const popup = (options)=>{
    setPopups([
     ...popups,
      options
    ])
  }


  const simplePopupFactory = (color, iconPath) => options=>{
    const innerOptions = typeof options === "string" ? {message: options}: options
    popup({
      color,
      iconPath,
      ...innerOptions
    })
  }

  const info = simplePopupFactory("info", mdiInformation)
  const error = simplePopupFactory("error", mdiAlertOctagon)
  const success = simplePopupFactory("success", mdiCheck)

  return (
    <PopupContext.Provider value={{ info, error, success, popup }}>
      {
        popups && popups.map((popup, index)=>{
          return (
            <Popup
              {...popup} 
              key={index}
              onClose={handlePopupClose(popup,index)}
              open={true}
            />
          )
        })
      }
      {children}
    </PopupContext.Provider>
  );
}