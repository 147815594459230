import { Paper, Box, Button } from "@mui/material"
import EmpresaAutocomplete from "../../components/EmpresaAutocomplete"
import { useLocalidades } from "../../contexts/LocalidadesProvider";
import { useEffect, useState } from "react";
import PlanillasList from "./PlanillasList";
import AddItemBox from "../../components/AddItemBox";
import { postPlanilla } from "../../client/Planilla";
import { usePlanillas } from "./PlanillasContext";
import { usePopup } from "../../contexts/PopupProvider"

const PlanillaSelectPanel = () =>{
  const { empresas } = useLocalidades();
  const { planillas, empresaSelected, setEmpresaSelected } = usePlanillas();
  const [ planillaNombre, setPlanillaNombre] = useState("");
  const popup = usePopup();

  useEffect(()=>{
    if(empresas.state.length === 1){
      setEmpresaSelected(empresas.state[0]);
    }
  }, [ empresas.state ])

  const handleAddNewPlanilla = (nombre) => {
    postPlanilla({
      nombre: nombre,
      empresa_id: empresaSelected?.empresa_id,
      schema: []
    })
    .then(planilla=>{
      planillas.upsert(planilla)
      setPlanillaNombre("")
    })
    .catch(()=>{
      popup.error("No se pudo crear la planilla. Inténtelo más tarde.")
    })
  }

  return (
    <Paper sx={{borderRadius:0,minWidth:260,width:260,maxWidth:260}}>
      <Box sx={{p:1}}>
        <EmpresaAutocomplete
          label="Seleccione Empresa"
          empresas={empresas.state} 
          value={empresaSelected}
          onChange={(_,v)=> {
            setEmpresaSelected(v)
          }}
        />
      </Box>
      {/*
        <HtmlTooltip placement="right" title={<EnDesarrollo/>}>
          <Box sx={{p:1}}>
            <Button disabled fullWidth size="small" startIcon={<Icon path={icons.planilla} size={1}/>} variant="outlined">
              Ejemplos
            </Button>
          </Box>
        </HtmlTooltip>
      */}
      <PlanillasList empresaId={empresaSelected?.empresa_id}/>
      <AddItemBox value={planillaNombre} onSubmit={handleAddNewPlanilla}/>
    </Paper>
  )
}

export default PlanillaSelectPanel;