import { useState } from "react";
//import EmpresaSearchPanel from "../../components/EmpresaSearchPanel";
//import CampoSearchPanel from "../../components/CampoSearchPanel";
import EmpresaAutocomplete from "../../components/EmpresaAutocomplete";
import CampoAutocomplete from "../../components/CampoAutocomplete";
import SupervisoresTable from "./SupervisoresTable"
import AgregarSupervisor from "./AgregarSupervisor";
import MainContainer from "../../components/MainContainer";
import BaseBox from "../../components/BaseBox";
import NavDrawer from "../../components/Drawer/NavDrawer";
import { SupervisoresProvider } from "../../contexts/SupervisoresProvider";
import { Card, Stack } from "@mui/material";
import { useLocalidades } from "../../contexts/LocalidadesProvider";

const Supervisores = ()=>{
  const { empresas, campos } = useLocalidades()
  const [ empresaSelected, setEmpresaSelected ] = useState(null)
  const [ campoSelected, setCampoSelected ] = useState(null)
  
  return <BaseBox>
    <NavDrawer/>
    <SupervisoresProvider empresaId={empresaSelected?.empresa_id} campoId={campoSelected?.campo_id}>
      <MainContainer>
        <Card sx={{mb:2,p:2,gap:2}}>
          <Stack spacing={2}>
            <EmpresaAutocomplete
              label="Seleccione Empresa"
              empresas={empresas.state}
              value={empresaSelected}
              onChange={(e,v)=>{
                setEmpresaSelected(v);
              }}
            />
            <CampoAutocomplete
              label="Seleccione Campo"
              campos={campos.state?.filter(c=>c.empresa_id===empresaSelected?.empresa_id)}
              value={campoSelected}
              onChange={(e,v)=>{
                setCampoSelected(v)
              }}
            />
          </Stack>
        </Card>
        <AgregarSupervisor campoId={campoSelected?.campo_id}/>
        <SupervisoresTable empresaId={empresaSelected?.empresa_id} campoId={campoSelected?.campo_id}/>
      </MainContainer>
    </SupervisoresProvider>
  </BaseBox>
}

export default Supervisores;