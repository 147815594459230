import { useState } from 'react'
import { Card } from '@mui/material'
import EmpresaAutocomplete from './EmpresaAutocomplete'
import { useLocalidades } from '../contexts/LocalidadesProvider'

const EmpresaSearchPanel = ({ onSelect })=>{
  const [ empresaSelected, setEmpresaSelected ] = useState(null)
  const { empresas } = useLocalidades()
  
  return (
    <Card sx={{mb:1,p:1}}>
      <EmpresaAutocomplete
        label="Seleccione Empresa"
        empresas={empresas.state}
        value={empresaSelected}
        onChange={(e,v)=>{
          setEmpresaSelected(v);
          onSelect(v?.empresa_id)
        }}
      />
    </Card>
  )
}
export default EmpresaSearchPanel