import { 
  axiosDeleteByIdFactory,
  axiosGetByIdFactory, 
  axiosGetFactory, 
  axiosPostFactory, 
  axiosPutByIdFactory
} from "../axios";

export const getCuarteles = axiosGetFactory('/cuartel')
export const getCuartelById = axiosGetByIdFactory('/cuartel')
export const postCuartel = axiosPostFactory('/cuartel')
export const putCuartel = axiosPutByIdFactory('/cuartel')
export const deleteCuartelById = axiosDeleteByIdFactory('/cuartel')