import { useDrawer } from "../../contexts/Drawer";
import { Toolbar, IconButton, Box } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { Icon } from "@mdi/react";
import { mdiMenu } from "@mdi/js";
import DrawerMenu from "./DrawerMenu"
import UsuarioDrawerItem from "./UsuarioDrawerItem"
import LogoutDrawerItem from "./LogoutDrawerItem"
import Drawer from "../Drawer"

const NavDrawer = ({children})=> {
  const { open, toggleDrawer } = useDrawer();

  return (
    <Drawer variant="permanent" open={open}  sx={{
      "& .MuiPaper-root":{
        overflowX: "hidden",
        backgroundColor: theme=>theme.palette.primary.main,
        color: theme=>theme.palette.primary.contrastText,
      },
      "& .Mui-selected:after":{
        position: "absolute",
        left: 2,
        width: 4,
        height: 20,
        borderRadius: 1,
        backgroundColor: theme =>theme.palette.secondary.main,
        content: '""',
      },
      "& .MuiListItemButton-root:hover":{
        backgroundColor: theme => alpha(theme.palette.secondary.main,0.1),
      },
      "& .MuiListItemButton-root.Mui-selected:not(:hover)":{
        backgroundColor: theme => alpha(theme.palette.secondary.main,0.2),
      },
      "& .MuiListItemIcon-root":{
        color: theme=>theme.palette.primary.contrastText,
      },
      "& nav .MuiListItemButton-root:not(.Mui-selected)":{
        opacity: 0.7,
        "& .MuiListItemIcon-root":{
          opacity: 0.5,
        },
      },
      "& .MuiTypography-root":{
        color: theme=>theme.palette.primary.contrastText,
      }
    }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '20px',
          }}
          >
          <Icon path={mdiMenu} size={1}/>
        </IconButton>
      </Toolbar>
      <DrawerMenu/>
      <Box sx={{py:1}}>
        <UsuarioDrawerItem/>
        <LogoutDrawerItem/>
      </Box>
    </Drawer>
  )
}

export default NavDrawer