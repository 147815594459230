import { Autocomplete, TextField } from "@mui/material";
import { useEffect } from "react";

const CampoAutocomplete = ({campos, label, onChange,...props})=>{
  const isDisabled = !campos || campos.length === 0;
  
  useEffect(()=>{
    if(campos?.length === 1){
      onChange(null, campos[0])
    }
  },[campos])

  return <Autocomplete
    {...props}
    onChange={onChange}
    disabled={isDisabled}
    options={campos}
    autoHighlight
    getOptionLabel={(option) => option.nombre}
    getOptionKey={option=>option.campo_id}
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // disable autocomplete and autofill
        }}
      />
    )}
  />
}

export default CampoAutocomplete;