import { mdiHelpRhombus } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Alert, Checkbox, FormControlLabel, TextField } from "@mui/material"
import Select from "../../components/Select"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { useEffect } from "react";

const onBlurStrategy = ({onChange, format })=>{
  return e => {
    const value = e.target.value
    let n = format(value) 
    onChange( n )
  }
}

const checkLimitsText = ({ lt, lte, gt, gte }) => {
  const messages = [
    gt !== undefined && `mayor que ${gt}`,
    gte !== undefined && `mayor o igual que ${gte}`,
    lt !== undefined && `menor que ${lt}`,
    lte !== undefined && `menor o igual que ${lte}`,
  ].filter(Boolean);

  return messages.length > 0 ? "El valor debe ser " + messages.join(" y ") : undefined
}

const checkLimitsError = ({ lt, lte, gt, gte }) => value => {
  const checks = [
    gt === undefined || value > gt,
    gte === undefined || value >= gte,
    lt === undefined || value < lt,
    lte === undefined || value <= lte,
  ]
  return value != null && checks.some(x => !x)
}


const Fields = {
  int: ({ value, onChange, label, disabled, ...props  })=>(
    <TextField
      value={value}
      label={label}
      onChange={e=>onChange(e.target.value)}
      onBlur={onBlurStrategy({ onChange, format: x => {
        const n = /(-?)(\d+)/.exec(x);
        if (!n) return x;
        const [, sign, integer = "0"] = n;
        return sign + integer;
      }})}
      helperText={(!value || checkLimitsError(props)(value)) && checkLimitsText(props)}
      error={checkLimitsError(props)(value)}
      fullWidth
      disabled={disabled}
    />
  ),
  decimal: ({ value, onChange, label, precision: p, disabled, ...props })=>(
    <TextField
      value={value}
      label={label}
      onChange={e=>onChange(e.target.value)}
      onBlur={onBlurStrategy({
        onChange,
        format: x => {
          const n = /(-?)(\d+)(?:\.(\d*))?/.exec(x)
          if(!n) return x;
          const [,sign,integer="0",decimal=""] = n
          const truncatedDecimal = decimal.slice(0,p).padEnd(p,"0");
          return sign + integer.padEnd(1, "0") + ( p > 0 ? "." + truncatedDecimal : ""); 
        }
      })}
      helperText={(!value || checkLimitsError(props)(value)) && checkLimitsText(props)}
      error={checkLimitsError(props)(value)}
      fullWidth
      disabled={disabled}
    />
  ),
  text: ({ value,onChange,label,disabled })=>(
    <TextField
      value={value}
      label={label}
      onChange={e=>onChange(e.target.value)}
      fullWidth
      disabled={disabled}
    />
  ),
  boolean: ({ value, onChange, label, disabled}) => (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          checked={value}
          onChange={e=>onChange(e.target.checked)}
          disabled={disabled}
        />
      }
    />
  ),
  enum: ({ value, onChange, label, enum: _enum, disabled }) => (
    <Select
      value={value}
      label={label}
      onChange={onChange}
      options={_enum}
      fullWidth
      disabled={disabled}
    />
  ),
  time: ({ value, onChange, label, disabled}) =>(
    <TextField
      label={label}
      value={value}
      onChange={(event) => {
        const { value } = event.target;
        if (/^[\d:]*$/.test(value)) {
          if (value.length === 2 && !value.includes(':')) {
            onChange(`${value}:`);
          } else if (value.length > 5) {
            onChange(value.substring(0, 5));
          } else {
            onChange(value);
          }
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Backspace" && value?.endsWith(":")) {
          e.preventDefault();
          onChange(value.substring(0, value.length - 2));
        }
      }}
      onBlur={() => {
        const [hours, minutes] = (value?? "00:00").split(':').map(Number);
        let correctedHours = hours < 0 ? 0 : hours >= 24 ? 23 : hours;
        let correctedMinutes = minutes < 0 ? 0 : minutes >= 60 ? 59 : minutes;
        const pad = x => (x?.toString() ?? "").padStart(2,"0");
        onChange(`${pad(correctedHours)}:${pad(correctedMinutes)}`);
      }}
      fullWidth
      disabled={disabled}
    />
  ),
  date: ({ value, onChange, label, disabled}) => (
    <DatePicker
      onChange={
        v => {
          try{
            let nv = (v??new Date()).toISOString().split("T")[0];
            onChange(nv)
          }catch(err){
            
          }
        }
      }
      value={value ? dayjs(value) : null}
      label={label}
      format="DD/MM/YYYY"
      fullWidth
      disabled={disabled}
    />
  )
}

const Default = ({ type, label }) => (
  <Alert
    severity="warning"
    icon={<Icon path={mdiHelpRhombus} size={1} />}
  >
    El campo <code><b>"{ label }"</b> de tipo <b>{ type }</b> no está implementado. </code>
    <br/>
    Si ve este mensaje comuníquese con el administrador del sistema.
  </Alert>
)

const FieldFactory = ({type, ...params})=>{
  const Field = Fields[type] ?? Default;
  return <Field type={type} {...params}/>
}

export default FieldFactory;