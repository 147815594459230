import { createContext, useContext } from "react"

export const PlanillaEditorContext = createContext({
  planilla: null,
  header: {
    state: null,
    setItem: ()=>{},
    removeItem: ()=>{}
  },
  schema: [],
  consolidada: false
})

export const usePlanillaEditor = () => useContext(PlanillaEditorContext)

