import { formatDate } from "../../utils/date";

const sanitizeDataType = (value)=>{
  return typeof value === 'boolean' ? (value? 'SI':'NO') : String(value);
}

export const generateHistoricoColumns = (schema=[]) => [
  {
    key: "reporte_id",
    header: "ID Reporte",
    columnProps: { sx:{width:120, textAlign: 'left'} }
  },
  {
    key: "createdAt",
    header: "Fecha",
    columnProps: { sx:{width:120, textAlign: 'left'} },
    Cell: ({row})=>formatDate(row.createdAt)
  },
  {
    key: "supervisor",
    header: "Supervisor",
    columnProps: { sx:{width:120, textAlign: 'left'} }
  },
  ...schema.map((item)=>(
    {
      key: "data." + item.key,
      header: item.nombre,
      Cell: ({row})=>sanitizeDataType(row.data[item.key]),
      columnProps: { sx:{ textAlign: 'center'} },
    }
  ))
]